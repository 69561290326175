import React, { useState, useEffect } from "react";
import Header from "./Homepage/Header";
import Footer from "./Homepage/Footer";
import ReactGA from "react-ga";

ReactGA.initialize("UA-171309197-1");

export default function PrivacyPolicy(props) {
  useEffect(() => {
    // console.log(props)
    window.scrollTo(0, 0);
  }, []);

  let [lang, setLang] = useState("en");

  useEffect(() => {
    lang = localStorage.getItem("lang");
    ReactGA.pageview(props.match.url + " " + lang);
    setLang(lang);
  }, [lang]);

  function changeLang(e) {
    localStorage.setItem("lang", e.target.value);
    setLang(e.target.value);
  }

  return (
    <div className="aboutus-section">
      <Header {...props} changeLang={changeLang} lang={lang} />
      <div
        id="siteContent"
        className="page-heading  animate__animated animate__fadeInDown"
        style={{ backgroundImage: "url(refund-policy.jpg)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              {lang === "en" ? <h2>Privacy Policy</h2> : null}
              {lang === "hi" ? <h2>गोपनीयता नीति</h2> : null}
              {lang === "pb" ? <h2>ਪਰਾਈਵੇਟ ਨੀਤੀ</h2> : null}
            </div>
          </div>
        </div>
      </div>
      <div className="refund-policy my-5">
        <div className="container">
          {lang === "en" ? (
            <div className="row">
              <div className="col-md-8  animate__animated animate__fadeInDown">
                <h1>Privacy Policy – Your Privacy Matters</h1>
                <p>
                  Shraman Health Care how your information is used and shared,
                  and we appreciate your trust that we will do so carefully and
                  sensibly. This notice describes Shraman HealthCare privacy
                  policy. By visiting this site, you are accepting the Privacy
                  Policy and our Terms and Conditions. The information we learn
                  from Shraman HealthCare customers helps us to personalize and
                  to improve your experience on our Website. Here are the types
                  of information we gather:
                </p>

                <h3>Information You Give Us</h3>
                <p>
                  We receive and store the information you enter on{" "}
                  <a href="https://shramanhealthcare.com/">
                    https://shramanhealthcare.com/
                  </a>
                  {""} or give us in any other way. This information is used for
                  responding to requests, customizing website features,
                  improving our website, and communicating with you.
                </p>

                <h3>Automatic Information</h3>
                <p>
                  We also receive and store different types of data &
                  information whenever you interact with us. We use “cookies,”
                  and we obtain certain types of data & information when your
                  Web browser accesses{""}
                  <a href="https://shramanhealthcare.com/">
                    https://shramanhealthcare.com/
                  </a>
                  .
                  <p>
                    Information about our customers is an important part of our
                    health care business, and we are pledged to secure it with
                    us. We share customer information only as described below
                    and with subsidiaries of Shraman HealthCare:
                  </p>
                </p>
                <h3>Business Transfers</h3>
                <p>
                  while developing our business, we might sell or buy stores,
                  subsidiaries, or business units. In such transactions, the
                  information of our customers generally is one of the
                  transferred business assets but adheres to the promises made
                  in any pre-existing Privacy Notice (unless, of course, the
                  customer consents otherwise). Also, in the unlikely event that
                  Shraman HealthCare or substantially all of its assets are
                  acquired, the information of our customers will be one of the
                  transferred assets.
                </p>

                <h3>Protection of Our Website and Others</h3>
                <p>
                  We release accounts and other private data & information when
                  we believe that the release is applicable to comply with the
                  law; enforce or apply our Terms and Conditions and other
                  agreements; or protect the rights, property, or safety of
                  Shraman HealthCare, our users, or others. This also includes
                  the exchange of information with other companies and
                  organizations for credit risk reduction and fraud protection.
                  However, this does not include selling, sharing, or otherwise
                  disclosing any personal information of our customers for
                  commercial purposes in violation of the commitments set forth
                  in this Privacy Policy.
                </p>
                <p>
                  Other than as set out above, you will receive a notification
                  whenever any of your information might go to third parties,
                  and you will have an opportunity to decide whether or not to
                  share the information.
                </p>
                <p>
                  As discussed above, you can always decide any action regarding
                  your information, even though it might be necessary to make a
                  purchase or to take advantage of certain features of our Web
                  site.
                </p>

                <h2>Our Commitment to Data Security</h2>
                <p>
                  To prevent illegal access and to maintain data accuracy, and
                  ensure the legal/right use of information, we have put in
                  place appropriate physical, electronic, and managerial
                  procedures to secure any of the information we collect online.
                  It is also necessary for you to prevent any illegal access to
                  your password and to your computer. Users have to make sure to
                  sign off when using a shared computer.
                </p>

                <h2>Children’s Privacy</h2>
                <p>
                  Privacy protection of children is extremely important. For
                  that reason, we never collect information on our website from
                  customers under 13, and no part of {""}
                  <a href="https://shramanhealthcare.com/">
                    https://shramanhealthcare.com/
                  </a>{" "}
                  is structured to attract anyone under 13.
                </p>

                <h2>Conditions of Use, Notices, and Revisions</h2>
                <p>
                  If you choose to visit {""}
                  <a href="https://shramanhealthcare.com/">
                    https://shramanhealthcare.com/
                  </a>
                  , your visit and any dispute over privacy are subject to this
                  Privacy Policy and our Terms and Conditions. If you have any
                  problems with privacy agreements on our Website, please
                  contact us and we will try to resolve them. Our Privacy Policy
                  and the Terms and Conditions may change accordingly for our
                  customers. We may email periodic reminders of our notices and
                  conditions, but you should check{" "}
                  <a href="https://shramanhealthcare.com/">
                    https://shramanhealthcare.com/
                  </a>
                  {""} frequently to see recent changes. Unless stated
                  otherwise, Shraman HealthCare Privacy Policy (current) applies
                  to all personal information that we have collected about you
                  and your account. We will make sure not to change our policies
                  and practices to make our customer’s information less
                  protective.
                </p>
              </div>
              <div className="col-sm-4 col-12">
                <div className="refundimg  animate__animated animate__fadeInDown">
                  <img
                    src="refundpolicy.jpg"
                    className="img-fluid"
                    alt="refund policy"
                  />
                </div>
              </div>
            </div>
          ) : null}

          {/* hindi section goes here */}

          {lang === "hi" ? (
            <div className="row">
              <div className="col-md-8  animate__animated animate__fadeInDown">
                <h1>गोपनीयता नीति – आपकी गोपनीयता मायने रखती है</h1>
                <p>
                  शरमन हेल्थ केयर आपकी जानकारी का उपयोग और साझा करने के तरीके के
                  बारे में जानकारी देता है, और हम आपके इस विश्वास की सराहना करते
                  हैं कि हम ऐसा सावधानी और समझदारी से करेंगे। यह नोटिस शरमन
                  हेल्थकेयर की गोपनीयता नीति का वर्णन करता है। इस साइट पर जाकर,
                  आप गोपनीयता नीति और हमारे नियम और शर्तों को स्वीकार कर रहे
                  हैं। शरमन हेल्थकेयर ग्राहकों से हमें जो जानकारी मिलती है, वह
                  हमें आपकी वेबसाइट पर आपके अनुभव को निजीकृत करने और बेहतर बनाने
                  में मदद करती है। यहाँ वे जानकारी दी गई है जो हम इकट्ठा करते
                  हैं:
                </p>

                <h3>आपके द्वारा हमें दी गई जानकारी</h3>
                <p>
                  हम आपके द्वारा दर्ज की गई जानकारी प्राप्त करते हैं और संग्रहीत
                  करते हैं{" "}
                  <a href="https://shramanhealthcare.com/">
                    https://shramanhealthcare.com/
                  </a>
                  {""} या किसी अन्य तरीके से हमें दें। इस जानकारी का उपयोग
                  अनुरोधों का जवाब देने, वेबसाइट सुविधाओं को अनुकूलित करने,
                  हमारी वेबसाइट को बेहतर बनाने और आपसे संवाद करने के लिए किया
                  जाता है.
                </p>

                <h3>स्वचालित सूचना</h3>
                <p>
                  जब भी आप हमसे बातचीत करते हैं तो हम विभिन्न प्रकार के डेटा और
                  जानकारी प्राप्त करते हैं और संग्रहीत करते हैं। हम "कुकीज़" का
                  उपयोग करते हैं, और जब आपका वेब ब्राउज़र एक्सेस करता है तो हम
                  कुछ प्रकार के डेटा और जानकारी प्राप्त करते हैं{""}
                  <a href="https://shramanhealthcare.com/">
                    https://shramanhealthcare.com/
                  </a>
                  .
                  <p>
                    हमारे ग्राहकों के बारे में जानकारी हमारे स्वास्थ्य सेवा
                    व्यवसाय का एक महत्वपूर्ण हिस्सा है, और हम इसे अपने साथ
                    सुरक्षित रखने के लिए प्रतिबद्ध हैं। हम ग्राहकों की जानकारी
                    केवल नीचे वर्णित तरीके से और शर्मन हेल्थकेयर की सहायक
                    कंपनियों के साथ साझा करते हैं:
                  </p>
                </p>
                <h3>व्यवसाय स्थानान्तरण</h3>
                <p>
                  अपने व्यवसाय को विकसित करते समय, हम स्टोर, सहायक कंपनियों या
                  व्यावसायिक इकाइयों को बेच या खरीद सकते हैं। ऐसे लेन-देन में,
                  हमारे ग्राहकों की जानकारी आम तौर पर हस्तांतरित व्यावसायिक
                  परिसंपत्तियों में से एक होती है, लेकिन किसी भी पूर्व-मौजूदा
                  गोपनीयता नोटिस में किए गए वादों का पालन करती है (जब तक कि,
                  निश्चित रूप से, ग्राहक अन्यथा सहमति नहीं देता)। इसके अलावा, इस
                  अप्रत्याशित घटना में कि शरमन हेल्थकेयर या इसकी सभी संपत्तियां
                  अधिग्रहित की जाती हैं, हमारे ग्राहकों की जानकारी हस्तांतरित
                  संपत्तियों में से एक होगी।
                </p>

                <h3>हमारी वेबसाइट और अन्य की सुरक्षा</h3>
                <p>
                  हम खाते और अन्य निजी डेटा और जानकारी तब जारी करते हैं जब हमें
                  लगता है कि यह रिलीज़ कानून का पालन करने, हमारे नियमों और
                  शर्तों और अन्य समझौतों को लागू करने या लागू करने के लिए
                  उपयुक्त है; या शरमन हेल्थकेयर, हमारे उपयोगकर्ताओं या अन्य
                  लोगों के अधिकारों, संपत्ति या सुरक्षा की रक्षा करता है। इसमें
                  क्रेडिट जोखिम में कमी और धोखाधड़ी से सुरक्षा के लिए अन्य
                  कंपनियों और संगठनों के साथ जानकारी का आदान-प्रदान भी शामिल है।
                  हालाँकि, इसमें इस गोपनीयता नीति में निर्धारित प्रतिबद्धताओं का
                  उल्लंघन करते हुए वाणिज्यिक उद्देश्यों के लिए हमारे ग्राहकों की
                  किसी भी व्यक्तिगत जानकारी को बेचना, साझा करना या अन्यथा प्रकट
                  करना शामिल नहीं है।
                </p>
                <p>
                  ऊपर बताए गए के अलावा, जब भी आपकी कोई जानकारी किसी तीसरे पक्ष
                  को दी जाएगी, तो आपको एक सूचना प्राप्त होगी, और आपके पास यह
                  निर्णय लेने का अवसर होगा कि जानकारी साझा करनी है या नहीं।
                </p>
                <p>
                  जैसा कि ऊपर चर्चा की गई है, आप हमेशा अपनी जानकारी के संबंध में
                  कोई भी कार्रवाई करने का निर्णय ले सकते हैं, भले ही इसके लिए
                  खरीदारी करना या हमारी वेबसाइट की कुछ सुविधाओं का लाभ उठाना
                  आवश्यक हो।
                </p>

                <h2>डेटा सुरक्षा के प्रति हमारी प्रतिबद्धता</h2>
                <p>
                  अवैध पहुँच को रोकने और डेटा सटीकता बनाए रखने तथा सूचना के
                  कानूनी/सही उपयोग को सुनिश्चित करने के लिए, हमने ऑनलाइन एकत्रित
                  की गई किसी भी जानकारी को सुरक्षित रखने के लिए उचित भौतिक,
                  इलेक्ट्रॉनिक और प्रबंधकीय प्रक्रियाएँ स्थापित की हैं। आपके लिए
                  यह भी आवश्यक है कि आप अपने पासवर्ड और अपने कंप्यूटर तक किसी भी
                  अवैध पहुँच को रोकें। उपयोगकर्ताओं को साझा कंप्यूटर का उपयोग
                  करते समय साइन ऑफ करना सुनिश्चित करना होगा।
                </p>

                <h2>बच्चों की गोपनीयता</h2>
                <p>
                  बच्चों की निजता की सुरक्षा अत्यंत महत्वपूर्ण है। इसी कारण से,
                  हम अपनी वेबसाइट पर 13 वर्ष से कम आयु के ग्राहकों से कभी भी
                  जानकारी एकत्र नहीं करते हैं, और इसका कोई भी भाग{""}
                  <a href="https://shramanhealthcare.com/">
                    https://shramanhealthcare.com/
                  </a>{" "}
                  यह 13 वर्ष से कम आयु के किसी भी व्यक्ति को आकर्षित करने के लिए
                  बनाया गया है।{" "}
                </p>

                <h2>उपयोग की शर्तें, नोटिस और संशोधन</h2>
                <p>
                  यदि आप यात्रा करना चुनते हैं {""}
                  <a href="https://shramanhealthcare.com/">
                    https://shramanhealthcare.com/
                  </a>
                  आपकी यात्रा और गोपनीयता पर कोई भी विवाद इस गोपनीयता नीति और
                  हमारे नियमों और शर्तों के अधीन है। यदि आपको हमारी वेबसाइट पर
                  गोपनीयता समझौतों के साथ कोई समस्या है, तो कृपया हमसे संपर्क
                  करें और हम उन्हें हल करने का प्रयास करेंगे। हमारी गोपनीयता
                  नीति और नियम और शर्तें हमारे ग्राहकों के लिए तदनुसार बदल सकती
                  हैं। हम अपने नोटिस और शर्तों के बारे में समय-समय पर अनुस्मारक
                  ईमेल कर सकते हैं, लेकिन आपको जांच करनी चाहिए{" "}
                  <a href="https://shramanhealthcare.com/">
                    https://shramanhealthcare.com/
                  </a>
                  {""} हाल ही में हुए बदलावों को देखने के लिए अक्सर देखें। जब तक
                  अन्यथा न कहा जाए, शर्मन हेल्थकेयर गोपनीयता नीति (वर्तमान) आपके
                  और आपके खाते के बारे में हमारे द्वारा एकत्रित की गई सभी
                  व्यक्तिगत जानकारी पर लागू होती है। हम यह सुनिश्चित करेंगे कि
                  हम अपने ग्राहकों की जानकारी को कम सुरक्षात्मक बनाने के लिए
                  अपनी नीतियों और प्रथाओं में कोई बदलाव न करें।
                </p>
              </div>
              <div className="col-sm-4 col-12">
                <div className="refundimg  animate__animated animate__fadeInDown">
                  <img
                    src="refundpolicy.jpg"
                    className="img-fluid"
                    alt="refund policy"
                  />
                </div>
              </div>
            </div>
          ) : null}

          {/* punjabi content */}

          {lang === "pb" ? (
            <div className="row">
              <div className="col-md-8  animate__animated animate__fadeInDown">
                <h1>ਗੋਪਨੀਯਤਾ ਨੀਤੀ - ਤੁਹਾਡੀ ਗੋਪਨੀਯਤਾ ਮਾਇਨੇ ਰੱਖਦੀ ਹੈ</h1>
                <p>
                  ਸ਼ਰਮਨ ਹੈਲਥ ਕੇਅਰ ਤੁਹਾਡੀ ਜਾਣਕਾਰੀ ਦੀ ਵਰਤੋਂ ਅਤੇ ਸਾਂਝੀ ਕਿਵੇਂ ਕੀਤੀ
                  ਜਾਂਦੀ ਹੈ, ਅਤੇ ਅਸੀਂ ਤੁਹਾਡੇ ਭਰੋਸੇ ਦੀ ਕਦਰ ਕਰਦੇ ਹਾਂ ਕਿ ਅਸੀਂ ਇਸਨੂੰ
                  ਧਿਆਨ ਨਾਲ ਅਤੇ ਸਮਝਦਾਰੀ ਨਾਲ ਕਰਾਂਗੇ। ਇਹ ਨੋਟਿਸ ਸ਼ਰਮਨ ਹੈਲਥਕੇਅਰ
                  ਗੋਪਨੀਯਤਾ ਨੀਤੀ ਦਾ ਵਰਣਨ ਕਰਦਾ ਹੈ। ਇਸ ਸਾਈਟ 'ਤੇ ਜਾ ਕੇ, ਤੁਸੀਂ
                  ਗੋਪਨੀਯਤਾ ਨੀਤੀ ਅਤੇ ਸਾਡੇ ਨਿਯਮਾਂ ਅਤੇ ਸ਼ਰਤਾਂ ਨੂੰ ਸਵੀਕਾਰ ਕਰ ਰਹੇ ਹੋ।
                  ਸ਼ਰਮਨ ਹੈਲਥਕੇਅਰ ਗਾਹਕਾਂ ਤੋਂ ਅਸੀਂ ਜੋ ਜਾਣਕਾਰੀ ਸਿੱਖਦੇ ਹਾਂ ਉਹ ਸਾਡੀ
                  ਵੈੱਬਸਾਈਟ 'ਤੇ ਤੁਹਾਡੇ ਅਨੁਭਵ ਨੂੰ ਵਿਅਕਤੀਗਤ ਬਣਾਉਣ ਅਤੇ ਬਿਹਤਰ ਬਣਾਉਣ
                  ਵਿੱਚ ਸਾਡੀ ਮਦਦ ਕਰਦੀ ਹੈ। ਇੱਥੇ ਜਾਣਕਾਰੀ ਦੀਆਂ ਕਿਸਮਾਂ ਹਨ ਜੋ ਅਸੀਂ
                  ਇਕੱਠੀਆਂ ਕਰਦੇ ਹਾਂ:
                </p>

                <h3>ਤੁਹਾਡੇ ਦੁਆਰਾ ਸਾਨੂੰ ਦਿੱਤੀ ਗਈ ਜਾਣਕਾਰੀ</h3>
                <p>
                  ਅਸੀਂ ਤੁਹਾਡੇ ਦੁਆਰਾ ਦਰਜ ਕੀਤੀ ਗਈ ਜਾਣਕਾਰੀ ਪ੍ਰਾਪਤ ਕਰਦੇ ਹਾਂ ਅਤੇ ਸਟੋਰ
                  ਕਰਦੇ ਹਾਂ{" "}
                  <a href="https://shramanhealthcare.com/">
                    https://shramanhealthcare.com/
                  </a>
                  {""} ਜਾਂ ਸਾਨੂੰ ਕਿਸੇ ਹੋਰ ਤਰੀਕੇ ਨਾਲ ਦਿਓ। ਇਹ ਜਾਣਕਾਰੀ ਬੇਨਤੀਆਂ ਦਾ
                  ਜਵਾਬ ਦੇਣ, ਵੈੱਬਸਾਈਟ ਵਿਸ਼ੇਸ਼ਤਾਵਾਂ ਨੂੰ ਅਨੁਕੂਲਿਤ ਕਰਨ, ਸਾਡੀ
                  ਵੈੱਬਸਾਈਟ ਨੂੰ ਬਿਹਤਰ ਬਣਾਉਣ ਅਤੇ ਤੁਹਾਡੇ ਨਾਲ ਸੰਚਾਰ ਕਰਨ ਲਈ ਵਰਤੀ
                  ਜਾਂਦੀ ਹੈ।
                </p>

                <h3>ਆਟੋਮੈਟਿਕ ਜਾਣਕਾਰੀ</h3>
                <p>
                  ਜਦੋਂ ਵੀ ਤੁਸੀਂ ਸਾਡੇ ਨਾਲ ਗੱਲਬਾਤ ਕਰਦੇ ਹੋ ਤਾਂ ਅਸੀਂ ਵੱਖ-ਵੱਖ ਕਿਸਮਾਂ
                  ਦੇ ਡੇਟਾ ਅਤੇ ਜਾਣਕਾਰੀ ਪ੍ਰਾਪਤ ਅਤੇ ਸਟੋਰ ਕਰਦੇ ਹਾਂ। ਅਸੀਂ "ਕੂਕੀਜ਼" ਦੀ
                  ਵਰਤੋਂ ਕਰਦੇ ਹਾਂ, ਅਤੇ ਅਸੀਂ ਕੁਝ ਖਾਸ ਕਿਸਮਾਂ ਦੇ ਡੇਟਾ ਅਤੇ ਜਾਣਕਾਰੀ
                  ਪ੍ਰਾਪਤ ਕਰਦੇ ਹਾਂ ਜਦੋਂ ਤੁਹਾਡਾ ਵੈੱਬ ਬ੍ਰਾਊਜ਼ਰ{""}
                  <a href="https://shramanhealthcare.com/">
                    https://shramanhealthcare.com/
                  </a>
                  .
                  <p>
                    ਸਾਡੇ ਗਾਹਕਾਂ ਬਾਰੇ ਜਾਣਕਾਰੀ ਸਾਡੇ ਸਿਹਤ ਸੰਭਾਲ ਕਾਰੋਬਾਰ ਦਾ ਇੱਕ
                    ਮਹੱਤਵਪੂਰਨ ਹਿੱਸਾ ਹੈ, ਅਤੇ ਅਸੀਂ ਇਸਨੂੰ ਆਪਣੇ ਨਾਲ ਸੁਰੱਖਿਅਤ ਕਰਨ ਲਈ
                    ਵਚਨਬੱਧ ਹਾਂ। ਅਸੀਂ ਗਾਹਕ ਜਾਣਕਾਰੀ ਸਿਰਫ਼ ਹੇਠਾਂ ਦੱਸੇ ਅਨੁਸਾਰ ਸਾਂਝੀ
                    ਕਰਦੇ ਹਾਂ ਅਤੇ ਸ਼ਰਮਨ ਹੈਲਥਕੇਅਰ ਦੀਆਂ ਸਹਾਇਕ ਕੰਪਨੀਆਂ ਨਾਲ:
                  </p>
                </p>
                <h3>ਕਾਰੋਬਾਰੀ ਟ੍ਰਾਂਸਫਰ</h3>
                <p>
                  ਆਪਣੇ ਕਾਰੋਬਾਰ ਨੂੰ ਵਿਕਸਤ ਕਰਦੇ ਸਮੇਂ, ਅਸੀਂ ਸਟੋਰਾਂ, ਸਹਾਇਕ ਕੰਪਨੀਆਂ,
                  ਜਾਂ ਵਪਾਰਕ ਇਕਾਈਆਂ ਨੂੰ ਵੇਚ ਜਾਂ ਖਰੀਦ ਸਕਦੇ ਹਾਂ। ਅਜਿਹੇ ਲੈਣ-ਦੇਣ
                  ਵਿੱਚ, ਸਾਡੇ ਗਾਹਕਾਂ ਦੀ ਜਾਣਕਾਰੀ ਆਮ ਤੌਰ 'ਤੇ ਟ੍ਰਾਂਸਫਰ ਕੀਤੀਆਂ ਗਈਆਂ
                  ਵਪਾਰਕ ਸੰਪਤੀਆਂ ਵਿੱਚੋਂ ਇੱਕ ਹੁੰਦੀ ਹੈ ਪਰ ਕਿਸੇ ਵੀ ਪਹਿਲਾਂ ਤੋਂ ਮੌਜੂਦ
                  ਗੋਪਨੀਯਤਾ ਨੋਟਿਸ ਵਿੱਚ ਕੀਤੇ ਗਏ ਵਾਅਦਿਆਂ ਦੀ ਪਾਲਣਾ ਕਰਦੀ ਹੈ (ਜਦੋਂ ਤੱਕ
                  ਕਿ, ਬੇਸ਼ੱਕ, ਗਾਹਕ ਹੋਰ ਸਹਿਮਤੀ ਨਹੀਂ ਦਿੰਦਾ)। ਨਾਲ ਹੀ, ਜੇਕਰ ਸ਼ਰਮਨ
                  ਹੈਲਥਕੇਅਰ ਜਾਂ ਇਸਦੀਆਂ ਸਾਰੀਆਂ ਸੰਪਤੀਆਂ ਪ੍ਰਾਪਤ ਕੀਤੀਆਂ ਜਾਂਦੀਆਂ ਹਨ,
                  ਤਾਂ ਸਾਡੇ ਗਾਹਕਾਂ ਦੀ ਜਾਣਕਾਰੀ ਟ੍ਰਾਂਸਫਰ ਕੀਤੀਆਂ ਗਈਆਂ ਸੰਪਤੀਆਂ ਵਿੱਚੋਂ
                  ਇੱਕ ਹੋਵੇਗੀ।
                </p>

                <h3>ਸਾਡੀ ਵੈੱਬਸਾਈਟ ਅਤੇ ਹੋਰਾਂ ਦੀ ਸੁਰੱਖਿਆ</h3>
                <p>
                  ਅਸੀਂ ਖਾਤੇ ਅਤੇ ਹੋਰ ਨਿੱਜੀ ਡੇਟਾ ਅਤੇ ਜਾਣਕਾਰੀ ਉਦੋਂ ਜਾਰੀ ਕਰਦੇ ਹਾਂ
                  ਜਦੋਂ ਸਾਨੂੰ ਵਿਸ਼ਵਾਸ ਹੁੰਦਾ ਹੈ ਕਿ ਇਹ ਰਿਲੀਜ਼ ਕਾਨੂੰਨ ਦੀ ਪਾਲਣਾ ਕਰਨ;
                  ਸਾਡੇ ਨਿਯਮਾਂ ਅਤੇ ਸ਼ਰਤਾਂ ਅਤੇ ਹੋਰ ਸਮਝੌਤਿਆਂ ਨੂੰ ਲਾਗੂ ਕਰਨ ਜਾਂ ਲਾਗੂ
                  ਕਰਨ; ਜਾਂ ਸ਼ਰਮਨ ਹੈਲਥਕੇਅਰ, ਸਾਡੇ ਉਪਭੋਗਤਾਵਾਂ, ਜਾਂ ਹੋਰਾਂ ਦੇ
                  ਅਧਿਕਾਰਾਂ, ਜਾਇਦਾਦ ਜਾਂ ਸੁਰੱਖਿਆ ਦੀ ਰੱਖਿਆ ਕਰਨ ਲਈ ਲਾਗੂ ਹੁੰਦੀ ਹੈ। ਇਸ
                  ਵਿੱਚ ਕ੍ਰੈਡਿਟ ਜੋਖਮ ਘਟਾਉਣ ਅਤੇ ਧੋਖਾਧੜੀ ਦੀ ਸੁਰੱਖਿਆ ਲਈ ਹੋਰ ਕੰਪਨੀਆਂ
                  ਅਤੇ ਸੰਗਠਨਾਂ ਨਾਲ ਜਾਣਕਾਰੀ ਦਾ ਆਦਾਨ-ਪ੍ਰਦਾਨ ਵੀ ਸ਼ਾਮਲ ਹੈ। ਹਾਲਾਂਕਿ,
                  ਇਸ ਵਿੱਚ ਵੇਚਣਾ, ਸਾਂਝਾ ਕਰਨਾ, ਜਾਂ ਹੋਰ ਇਸ ਗੋਪਨੀਯਤਾ ਨੀਤੀ ਵਿੱਚ
                  ਨਿਰਧਾਰਤ ਵਚਨਬੱਧਤਾਵਾਂ ਦੀ ਉਲੰਘਣਾ ਕਰਦੇ ਹੋਏ ਵਪਾਰਕ ਉਦੇਸ਼ਾਂ ਲਈ ਸਾਡੇ
                  ਗਾਹਕਾਂ ਦੀ ਕਿਸੇ ਵੀ ਨਿੱਜੀ ਜਾਣਕਾਰੀ ਦਾ ਖੁਲਾਸਾ ਕਰਨਾ ਸ਼ਾਮਲ ਨਹੀਂ ਹੈ।
                </p>
                <p>
                  ਉੱਪਰ ਦੱਸੇ ਗਏ ਤੋਂ ਇਲਾਵਾ, ਤੁਹਾਨੂੰ ਇੱਕ ਸੂਚਨਾ ਪ੍ਰਾਪਤ ਹੋਵੇਗੀ ਜਦੋਂ
                  ਵੀ ਤੁਹਾਡੀ ਕੋਈ ਵੀ ਜਾਣਕਾਰੀ ਤੀਜੀ ਧਿਰ ਕੋਲ ਜਾ ਸਕਦੀ ਹੈ, ਅਤੇ ਤੁਹਾਡੇ
                  ਕੋਲ ਇਹ ਫੈਸਲਾ ਕਰਨ ਦਾ ਮੌਕਾ ਹੋਵੇਗਾ ਕਿ ਜਾਣਕਾਰੀ ਸਾਂਝੀ ਕਰਨੀ ਹੈ ਜਾਂ
                  ਨਹੀਂ ।
                </p>
                <p>
                  ਜਿਵੇਂ ਕਿ ਉੱਪਰ ਚਰਚਾ ਕੀਤੀ ਗਈ ਹੈ, ਤੁਸੀਂ ਹਮੇਸ਼ਾ ਆਪਣੀ ਜਾਣਕਾਰੀ
                  ਸੰਬੰਧੀ ਕੋਈ ਵੀ ਕਾਰਵਾਈ ਕਰਨ ਦਾ ਫੈਸਲਾ ਕਰ ਸਕਦੇ ਹੋ, ਭਾਵੇਂ ਇਹ ਖਰੀਦਣ
                  ਲਈ ਜਾਂ ਸਾਡੀ ਵੈੱਬਸਾਈਟ ਦੀਆਂ ਕੁਝ ਵਿਸ਼ੇਸ਼ਤਾਵਾਂ ਦਾ ਲਾਭ ਲੈਣ ਲਈ
                  ਜ਼ਰੂਰੀ ਹੋਵੇ।
                </p>

                <h2>ਡਾਟਾ ਸੁਰੱਖਿਆ ਪ੍ਰਤੀ ਸਾਡੀ ਵਚਨਬੱਧਤਾ</h2>
                <p>
                  ਗੈਰ-ਕਾਨੂੰਨੀ ਪਹੁੰਚ ਨੂੰ ਰੋਕਣ ਅਤੇ ਡੇਟਾ ਦੀ ਸ਼ੁੱਧਤਾ ਬਣਾਈ ਰੱਖਣ ਲਈ,
                  ਅਤੇ ਜਾਣਕਾਰੀ ਦੀ ਕਾਨੂੰਨੀ/ਸਹੀ ਵਰਤੋਂ ਨੂੰ ਯਕੀਨੀ ਬਣਾਉਣ ਲਈ, ਅਸੀਂ ਉਚਿਤ
                  ਭੌਤਿਕ, ਇਲੈਕਟ੍ਰਾਨਿਕ, ਅਤੇ ਪ੍ਰਬੰਧਕੀ ਪ੍ਰਕਿਰਿਆਵਾਂ ਬਣਾਈਆਂ ਹਨ ਤਾਂ ਜੋ
                  ਅਸੀਂ ਔਨਲਾਈਨ ਇਕੱਠੀ ਕੀਤੀ ਜਾਣ ਵਾਲੀ ਕਿਸੇ ਵੀ ਜਾਣਕਾਰੀ ਨੂੰ ਸੁਰੱਖਿਅਤ
                  ਕੀਤਾ ਜਾ ਸਕੇ। ਤੁਹਾਡੇ ਪਾਸਵਰਡ ਅਤੇ ਤੁਹਾਡੇ ਕੰਪਿਊਟਰ ਤੱਕ ਕਿਸੇ ਵੀ
                  ਗੈਰ-ਕਾਨੂੰਨੀ ਪਹੁੰਚ ਨੂੰ ਰੋਕਣਾ ਤੁਹਾਡੇ ਲਈ ਵੀ ਜ਼ਰੂਰੀ ਹੈ। ਉਪਭੋਗਤਾਵਾਂ
                  ਨੂੰ ਸਾਂਝਾ ਕੰਪਿਊਟਰ ਵਰਤਦੇ ਸਮੇਂ ਸਾਈਨ ਆਊਟ ਕਰਨਾ ਯਕੀਨੀ ਬਣਾਉਣਾ
                  ਹੋਵੇਗਾ।
                </p>

                <h2>ਬੱਚਿਆਂ ਦੀ ਗੋਪਨੀਯਤਾ</h2>
                <p>
                  ਬੱਚਿਆਂ ਦੀ ਗੋਪਨੀਯਤਾ ਸੁਰੱਖਿਆ ਬਹੁਤ ਮਹੱਤਵਪੂਰਨ ਹੈ। ਇਸ ਕਾਰਨ ਕਰਕੇ,
                  ਅਸੀਂ ਕਦੇ ਵੀ ਆਪਣੀ ਵੈੱਬਸਾਈਟ 'ਤੇ 13 ਸਾਲ ਤੋਂ ਘੱਟ ਉਮਰ ਦੇ ਗਾਹਕਾਂ ਤੋਂ
                  ਜਾਣਕਾਰੀ ਇਕੱਠੀ ਨਹੀਂ ਕਰਦੇ, ਅਤੇ ਇਸਦਾ ਕੋਈ ਹਿੱਸਾ ਨਹੀਂ {""}
                  <a href="https://shramanhealthcare.com/">
                    https://shramanhealthcare.com/
                  </a>{" "}
                  13 ਸਾਲ ਤੋਂ ਘੱਟ ਉਮਰ ਦੇ ਕਿਸੇ ਵੀ ਵਿਅਕਤੀ ਨੂੰ ਆਕਰਸ਼ਿਤ ਕਰਨ ਲਈ ਤਿਆਰ
                  ਕੀਤਾ ਗਿਆ ਹੈ।
                </p>

                <h2>ਵਰਤੋਂ ਦੀਆਂ ਸ਼ਰਤਾਂ, ਨੋਟਿਸ, ਅਤੇ ਸੋਧਾਂ</h2>
                <p>
                  ਜੇਕਰ ਤੁਸੀਂ ਜਾਣਾ ਚੁਣਦੇ ਹੋ {""}
                  <a href="https://shramanhealthcare.com/">
                    https://shramanhealthcare.com/
                  </a>
                  , ਤੁਹਾਡੀ ਫੇਰੀ ਅਤੇ ਗੋਪਨੀਯਤਾ ਬਾਰੇ ਕੋਈ ਵੀ ਵਿਵਾਦ ਇਸ ਗੋਪਨੀਯਤਾ ਨੀਤੀ
                  ਅਤੇ ਸਾਡੇ ਨਿਯਮਾਂ ਅਤੇ ਸ਼ਰਤਾਂ ਦੇ ਅਧੀਨ ਹੈ। ਜੇਕਰ ਤੁਹਾਨੂੰ ਸਾਡੀ
                  ਵੈੱਬਸਾਈਟ 'ਤੇ ਗੋਪਨੀਯਤਾ ਸਮਝੌਤਿਆਂ ਨਾਲ ਕੋਈ ਸਮੱਸਿਆ ਹੈ, ਤਾਂ ਕਿਰਪਾ
                  ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ ਅਤੇ ਅਸੀਂ ਉਨ੍ਹਾਂ ਨੂੰ ਹੱਲ ਕਰਨ ਦੀ ਕੋਸ਼ਿਸ਼
                  ਕਰਾਂਗੇ। ਸਾਡੀ ਗੋਪਨੀਯਤਾ ਨੀਤੀ ਅਤੇ ਨਿਯਮਾਂ ਅਤੇ ਸ਼ਰਤਾਂ ਸਾਡੇ ਗਾਹਕਾਂ
                  ਲਈ ਇਸ ਅਨੁਸਾਰ ਬਦਲ ਸਕਦੀਆਂ ਹਨ। ਅਸੀਂ ਆਪਣੇ ਨੋਟਿਸਾਂ ਅਤੇ ਸ਼ਰਤਾਂ ਦੇ
                  ਸਮੇਂ-ਸਮੇਂ 'ਤੇ ਰੀਮਾਈਂਡਰ ਈਮੇਲ ਕਰ ਸਕਦੇ ਹਾਂ, ਪਰ ਤੁਹਾਨੂੰ ਜਾਂਚ ਕਰਨੀ
                  ਚਾਹੀਦੀ ਹੈ{" "}
                  <a href="https://shramanhealthcare.com/">
                    https://shramanhealthcare.com/
                  </a>
                  {""} ਹਾਲੀਆ ਤਬਦੀਲੀਆਂ ਦੇਖਣ ਲਈ ਅਕਸਰ। ਜਦੋਂ ਤੱਕ ਹੋਰ ਨਾ ਕਿਹਾ ਜਾਵੇ,
                  ਸ਼ਰਮਨ ਹੈਲਥਕੇਅਰ ਗੋਪਨੀਯਤਾ ਨੀਤੀ (ਮੌਜੂਦਾ) ਤੁਹਾਡੇ ਅਤੇ ਤੁਹਾਡੇ ਖਾਤੇ
                  ਬਾਰੇ ਇਕੱਠੀ ਕੀਤੀ ਗਈ ਸਾਰੀ ਨਿੱਜੀ ਜਾਣਕਾਰੀ ਤੇ ਲਾਗੂ ਹੁੰਦੀ ਹੈ। ਅਸੀਂ
                  ਇਹ ਯਕੀਨੀ ਬਣਾਵਾਂਗੇ ਕਿ ਸਾਡੇ ਗਾਹਕਾਂ ਦੀ ਜਾਣਕਾਰੀ ਨੂੰ ਘੱਟ ਸੁਰੱਖਿਆਤਮਕ
                  ਬਣਾਉਣ ਲਈ ਆਪਣੀਆਂ ਨੀਤੀਆਂ ਅਤੇ ਅਭਿਆਸਾਂ ਨੂੰ ਨਾ ਬਦਲਿਆ ਜਾਵੇ।
                </p>
              </div>
              <div className="col-sm-4 col-12">
                <div className="refundimg  animate__animated animate__fadeInDown">
                  <img
                    src="refundpolicy.jpg"
                    className="img-fluid"
                    alt="privacy-policy"
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <Footer lang={lang} />
    </div>
  );
}
