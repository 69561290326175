import React, { useState, useEffect } from "react";
import Header from "./Homepage/Header";
import Footer from "./Homepage/Footer";
import ReactGA from "react-ga";

ReactGA.initialize("UA-171309197-1");

export default function ContactUs(props) {
  useEffect(() => {
    // console.log(props)
    window.scrollTo(0, 0);
  }, []);

  let [lang, setLang] = useState("en");

  useEffect(() => {
    lang = localStorage.getItem("lang");
    ReactGA.pageview(props.match.url + " " + lang);
    setLang(lang);
  }, [lang]);

  function changeLang(e) {
    localStorage.setItem("lang", e.target.value);
    setLang(e.target.value);
  }
  return (
    <div className="contactus-section">
      <Header {...props} changeLang={changeLang} lang={lang} />
      <div
        id="siteContent"
        className="page-heading  animate__animated animate__fadeInDown"
        style={{ backgroundImage: "url(contactus.jpg)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              {lang === "en" ? <h2>Contact Us</h2> : null}
              {lang === "hi" ? <h2>हमसे संपर्क करें</h2> : null}
              {lang === "pb" ? <h2>ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ</h2> : null}
            </div>
          </div>
        </div>
      </div>
      <div className="contactus-content my-5">
        <div className="container">
          {lang === "en" ? (
            <div className="row">
              <div className="col-sm-12 mb-5  animate__animated animate__fadeInLeft">
                <h1>Shraman HealthCare </h1>
              </div>
              <div className="col-sm-4">
                <div className="contact mb-3 animate__animated animate__fadeInDown animate__delay-2s">
                  <div className="contact-box">
                    <h5>Address</h5>
                    <p>235, Monika Towers, Jalandhar,Punjab, India</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="contact mb-3 animate__animated animate__fadeInDown animate__delay-3s">
                  <div className="contact-box">
                    <h5>Email Address</h5>
                    <p>shramanhealthcare@gmail.com</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="contact mb-3 animate__animated animate__fadeInDown animate__delay-4s">
                  <div className="contact-box">
                    <h5>Phone Number</h5>
                    <p>+91 93177-70773, +91 98728-13954, 0181 4153002</p>
                  </div>
                </div>
              </div>
              <div className="col-md-12 mt-5">
                <iframe
                  title="shraman healthcare address"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6816.256213245186!2d75.57986!3d31.327835!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1ba4a577d1db35b!2sShraman%20Health%20Care!5e0!3m2!1sen!2sin!4v1591031934793!5m2!1sen!2sin"
                  style={{ width: "100%" }}
                  height="450"
                  frameBorder="0"
                  allowFullscreen=""
                  aria-hidden="false"
                  tabIndex="0"
                ></iframe>
              </div>
            </div>
          ) : null}

          {/* hindi section  */}
          {lang === "hi" ? (
            <div className="row">
              <div className="col-sm-12 mb-5  animate__animated animate__fadeInLeft">
                <h1>श्रमण हेल्थ केयर</h1>
              </div>
              <div className="col-sm-4">
                <div className="contact mb-3 animate__animated animate__fadeInDown animate__delay-2s">
                  <div className="contact-box">
                    <h5>हमारा पता</h5>
                    <p>235, मोनिका टॉवर, मिलाप चौंक, जालन्धर (पंजाब) भारत।</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="contact mb-3 animate__animated animate__fadeInDown animate__delay-3s">
                  <div className="contact-box">
                    <h5>ईमेल पता</h5>
                    <p>shramanhealthcare@gmail.com</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="contact mb-3 animate__animated animate__fadeInDown animate__delay-4s">
                  <div className="contact-box">
                    <h5>फ़ोन नंबर</h5>
                    <p>+91 93177-70773, +91 98728-13954, 0181 4153002</p>
                  </div>
                </div>
              </div>
              <div className="col-md-12 mt-5">
                <iframe
                  title="shraman healthcare address"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6816.256213245186!2d75.57986!3d31.327835!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1ba4a577d1db35b!2sShraman%20Health%20Care!5e0!3m2!1sen!2sin!4v1591031934793!5m2!1sen!2sin"
                  style={{ width: "100%" }}
                  height="450"
                  frameBorder="0"
                  allowFullscreen=""
                  aria-hidden="false"
                  tabIndex="0"
                ></iframe>
              </div>
            </div>
          ) : null}

          {/* punjabi section */}

          {lang === "pb" ? (
            <div className="row">
              <div className="col-sm-12 mb-5  animate__animated animate__fadeInLeft">
                <h1>ਸ਼ਰਮਨ ਹੈਲਥਕੇਅਰ</h1>
              </div>
              <div className="col-sm-4">
                <div className="contact mb-3 animate__animated animate__fadeInDown animate__delay-2s">
                  <div className="contact-box">
                    <h5>ਸਾਡਾ ਪਤਾ</h5>
                    <p>235, ਮੋਨਿਕਾ ਟਾਵਰ, ਮਿਲਾਪ ਚੌਂਕ, ਜਲੰਧਰ (ਪੰਜਾਬ) ਇੰਡੀਆ</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="contact mb-3 animate__animated animate__fadeInDown animate__delay-3s">
                  <div className="contact-box">
                    <h5>ਈ - ਮੇਲ</h5>
                    <p>shramanhealthcare@gmail.com</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="contact mb-3 animate__animated animate__fadeInDown animate__delay-4s">
                  <div className="contact-box">
                    <h5>ਫੋਨ ਨੰਬਰ</h5>
                    <p>+91 93177-70773, +91 98728-13954, 0181 4153002</p>
                  </div>
                </div>
              </div>
              <div className="col-md-12 mt-5">
                <iframe
                  title="shraman healthcare address"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6816.256213245186!2d75.57986!3d31.327835!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1ba4a577d1db35b!2sShraman%20Health%20Care!5e0!3m2!1sen!2sin!4v1591031934793!5m2!1sen!2sin"
                  style={{ width: "100%" }}
                  height="450"
                  frameBorder="0"
                  allowFullscreen=""
                  aria-hidden="false"
                  tabIndex="0"
                ></iframe>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <Footer lang={lang} />
    </div>
  );
}
