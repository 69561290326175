import React, { useState, useEffect } from "react";
import Header from "./Homepage/Header";
import Footer from "./Homepage/Footer";
import ReactGA from "react-ga";

ReactGA.initialize("UA-171309197-1");

export default function Disclaimer(props) {
  useEffect(() => {
    // console.log(props)
    window.scrollTo(0, 0);
  }, []);

  let [lang, setLang] = useState("en");

  useEffect(() => {
    lang = localStorage.getItem("lang");
    ReactGA.pageview(props.match.url + " " + lang);
    setLang(lang);
  }, [lang]);

  function changeLang(e) {
    localStorage.setItem("lang", e.target.value);
    setLang(e.target.value);
  }

  return (
    <div className="aboutus-section">
      <Header {...props} changeLang={changeLang} lang={lang} />
      <div
        id="siteContent"
        className="page-heading  animate__animated animate__fadeInDown"
        style={{ backgroundImage: "url(refund-policy.jpg)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              {lang === "en" ? <h2>Terms & Conditions</h2> : null}
              {lang === "hi" ? <h2>नियम एवं शर्तें</h2> : null}
              {lang === "pb" ? <h2>ਨਿਯਮ ਅਤੇ ਸ਼ਰਤਾਂ</h2> : null}
            </div>
          </div>
        </div>
      </div>
      <div className="refund-policy my-5">
        <div className="container">
          {lang === "en" ? (
            <div className="row">
              <div className="col-md-8  animate__animated animate__fadeInDown">
                <div class="container">
                  <h1>Terms & Conditions – Shraman Health Care</h1>
                  <p>
                    Please read carefully the following terms and conditions as
                    your use of service is subject to your acceptance of and
                    conformity with the following terms and conditions
                    (“Terms”).
                  </p>
                  <p>
                    By accepting the use of any of our services, you agree that
                    you have read & understood our terms and conditions
                    carefully and are bound to adhere to these terms, regardless
                    of how you use our services. These terms and policies are
                    according to the provisions of the Information Technology
                    (Intermediaries guidelines) Rules, 2011 under the
                    Information Technology Act 2000. In these Terms, references
                    to “you”, “User” shall mean the end user accessing the
                    Website, and “we”, “us” and “our” shall mean Shraman Health
                    Care and its affiliates.
                  </p>
                  <h2>1. Introduction</h2>
                  <p>
                    <a href="https://shramanhealthcare.com/">
                      Shraman Health Care
                    </a>{" "}
                    is an e-commerce healthcare portal licensed by Shraman
                    Health Care , a health care company incorporated under the
                    Laws of India.
                  </p>
                  <p>
                    Use of the Website is offered to you conditioned on
                    acceptance without modification of all the terms &
                    conditions as well as notices contained in these Terms, as
                    may be posted on the Website from time to time.{" "}
                    <a href="https://shramanhealthcare.com/">
                      shramanhealthcare
                    </a>{" "}
                    at its sole discretion reserves the right not to accept a
                    User from registering on the Website without assigning any
                    reason thereof.
                  </p>
                  <h2>2. User Account, Password, and Security</h2>
                  <p>
                    Upon completing the Website’s registration process, you will
                    receive a password and account information. You will solely
                    be responsible for the confidentiality of the account and
                    password and are fully responsible for all activities that
                    occur under your password or account.{" "}
                    <a href="https://shramanhealthcare.com/">
                      shramanhealthcare
                    </a>{" "}
                    cannot and will not be liable for any loss or damage arising
                    from your failure to comply with this Section.
                  </p>
                  <h2>3. Services Offered</h2>
                  <p>
                    <a href="https://shramanhealthcare.com/">
                      shramanhealthcare
                    </a>{" "}
                    provides a number of health care services through the Web
                    Site (all such services, collectively, the “Service”). One
                    such service enables users to purchase original herbal
                    products from Shraman Health Care and its associates. The
                    User can purchase the products through the Website through
                    various methods of payments offered. The sale and purchase
                    of Products shall be additionally governed by specific
                    policies of sale, like return policy, cancellation policy,
                    etc.
                  </p>
                  <h2>4. Privacy Policy</h2>
                  <p>
                    The User hereby consents, expresses and agrees that he has
                    read carefully and now fully understands the Privacy Policy
                    of{" "}
                    <a href="https://shramanhealthcare.com/">Privacy Policy</a>.
                    The user also approves that the terms & contents of such
                    Privacy Policy are acceptable to him.
                  </p>
                  <h2>5. Limited User</h2>
                  <p>
                    The User agrees and undertakes not to reverse engineer,
                    modify, publish, license, create derivative works from,
                    transfer, copy, distribute, transmit, display, download,
                    perform, reproduce, or sell any information or software
                    obtained from the Website. Limited reproduction and copying
                    of the content of the Website are permitted provided that
                    Shraman Health Care name is stated as the source and prior
                    written permission of
                    <a href="https://shramanhealthcare.com/">
                      shramanhealthcare
                    </a>{" "}
                    is sought. For the removal of doubt, reproduction, copying
                    of the content for commercial or non-commercial purposes and
                    unwarranted modification of data and information within the
                    content of the Website is not permitted
                  </p>
                  <h2>6. User Conduct and Rules</h2>
                  <p>
                    {" "}
                    You agree and undertake to use the
                    <a href="https://shramanhealthcare.com/">
                      shramanhealthcare
                    </a>{" "}
                    only to post and upload messages and material that are
                    proper. You also accept to agree, and not as a limitation,
                    you undertake that when using a Service, you will not:
                  </p>
                  <ul>
                    <li>
                      Defame or otherwise violate any legal rights of others .
                    </li>
                    <li>
                      Publish or post any inappropriate, indecent or unlawful
                      topic, name & material or any information which anyone is
                      not related to;
                    </li>
                    <li>
                      Upload any file which is protected by intellectual
                      property laws unless you own the rights thereto or have
                      collected all necessary consents;
                    </li>
                    <li>
                      Upload or distribute corrupted files, or any other similar
                      software or programs that may damage the operation of the
                      Website or any user’s computer.
                    </li>
                    <li>
                      Conduct or forward surveys, contests or chain letters;
                    </li>
                    <li>
                      Falsify or delete any author attributions, legal or labels
                      of the origin or other proper notices or source of
                      software or proprietary designations or other material
                      contained in a file that is uploaded;
                    </li>
                    <li>
                      Violate any guidelines or code of conduct, which may be
                      useful to any particular Service;
                    </li>
                    <li>
                      Violate any regulations or applicable laws for the time
                      being in force in or outside India; and
                    </li>
                    <li>
                      Unethically manipulate or exploit these terms & conditions
                      or any other terms & conditions for the use of the website
                      which is not{" "}
                      <a href="https://shramanhealthcare.com/">
                        shramanhealthcare
                      </a>{" "}
                    </li>
                  </ul>
                  <h2>User Warranty and Representation :</h2>
                  <p>
                    The user guarantees, warrants, and approve that you are the
                    owner of the content which you submit or otherwise
                    authorized to use the content and that the content does not
                    violate upon the property or intellectual property rights or
                    any other rights of others. You also take accreditation that
                    to your knowledge, no action, suit, proceeding, or
                    investigation has been threatened relating to any content,
                    including trademark, copyright and trade name service mark
                    formerly or currently used by you in connection with the
                    Services rendered by{" "}
                    <a href="https://shramanhealthcare.com/">
                      shramanhealthcare
                    </a>{" "}
                  </p>
                  <h2>7. Intellectual Property Rights</h2>
                  <p>
                    <b>a)</b> Unless otherwise indicated or anything consist of
                    any conflicting or any proprietary material controlled or
                    owned by a third party and so expressly mentioned,{" "}
                    <a href="https://shramanhealthcare.com/">
                      shramanhealthcare
                    </a>{" "}
                    owns all the Intellectual Property Rights to and into the
                    mentioned website, including, without limitation, any and
                    all rights, the title and interest in and to utility models,
                    trademarks & trade names, service marks, copyright, related
                    rights, patents, designs, know-how, trade secrets and
                    inventions (whether patentable or not), goodwill, source
                    code, meta tags, databases, text, content, graphics, icons,
                    and hyperlinks. You agree that you shall not use, duplicate
                    or circulate any content from the Website belonging to{" "}
                    <a href="https://shramanhealthcare.com/">
                      shramanhealthcare
                    </a>{" "}
                    without obtaining authorization from{" "}
                    <a href="https://shramanhealthcare.com/">
                      shramanhealthcare
                    </a>{" "}
                    .
                  </p>
                  <p>
                    <b>b)</b> Notwithstanding the foregoing, it is expressly
                    clear that you will recognize ownership and shall solely be
                    liable for any content that you arrange or upload when using
                    any Service, which includes, and not limited to, any data or
                    any kind of information via images, photographs, music,
                    sound, or video which you may upload, transmit or store when
                    making use of our various health care services. However,
                    with respect to the product customization Service, you also
                    agree that by uploading and posting content on to the{" "}
                    <a href="https://shramanhealthcare.com/">
                      shramanhealthcare
                    </a>{" "}
                    for public viewing and duplication or use of your content by
                    third party users, you accept the User whereby you assign a
                    non-exclusive license for the use of the same.
                  </p>
                  <h2>8. Disclaimer of Warranties/Limitation of Liability</h2>
                  <p>
                    {" "}
                    <a href="https://shramanhealthcare.com/">
                      shramanhealthcare
                    </a>{" "}
                    has made sure that all the information on our Website is
                    correct,{" "}
                    <a href="https://shramanhealthcare.com/">
                      shramanhealthcare
                    </a>{" "}
                    neither license nor makes any portrayal regarding the
                    quality, certainty or completeness of any data, information,
                    product or Service. In no event shall{" "}
                    <a href="https://shramanhealthcare.com/">
                      shramanhealthcare
                    </a>{" "}
                    be liable for any direct, indirect, disciplinary,
                    accidental, special, consequential damages or any other
                    damages resulting from:
                  </p>
                  <p>
                    <b>(a)</b> To use the services or products or the inability
                    to use the services or products;
                  </p>
                  <p>
                    <b>(b)</b> Illegal access to or modification of the user’s
                    transmissions or the user’s data;
                  </p>
                  <p>
                    <b>(c)</b> Any other matter describing the services;
                    including, and not limited to, damages for loss of use, data
                    or profits, originating out of or in any way connected with
                    the use or performance of the{" "}
                    <a href="https://shramanhealthcare.com/">
                      shramanhealthcare
                    </a>{" "}
                    or its Services. Neither shall{" "}
                    <a href="https://shramanhealthcare.com/">
                      shramanhealthcare
                    </a>{" "}
                    be responsible for the lag or inability to use the Website
                    or its services, the arrangement of or failure to provide
                    Services, or for any kind of info, software, products,
                    services and associated graphics obtained through the
                    Website, or otherwise originating from the use of the
                    website, whether based on contract, tort, negligence, strict
                    liability or otherwise. Further,{" "}
                    <a href="https://shramanhealthcare.com/">
                      shramanhealthcare
                    </a>{" "}
                    shall not be held responsible if the website is not
                    available during periodic maintenance operations or any
                    unplanned suspension of connection to our website due to
                    technical reasons or for any reason beyond{" "}
                    <a href="https://shramanhealthcare.com/">
                      shramanhealthcare
                    </a>{" "}
                    ’s control. The user accepts that any information or data
                    obtained through the Website is done entirely at their own
                    foresight and risk and they will be solely answerable for
                    any damage to their computer systems or any loss of data
                    resulting from the download of such material and/or data.
                  </p>
                  <h2>9. Indemnification</h2>
                  <p>
                    The user agrees to defend You agree to compensate, defend
                    and hold harmless{" "}
                    <a href="https://shramanhealthcare.com/">
                      shramanhealthcare
                    </a>{" "}
                    from and against any and all losses, debt, allegations,
                    damages, costs and expenses (including legal fees and
                    expenditure in connection therewith and interest chargeable
                    thereon) argue against or incurred by{" "}
                    <a href="https://shramanhealthcare.com/">
                      shramanhealthcare
                    </a>{" "}
                    that arise out of, result from or may be payable by virtue
                    of, any breach or non-performance of any representation,
                    warranty or agreement made or obligation to be performed by
                    the user agreeing to these terms & conditions.
                  </p>
                  <h2>10. Pricing</h2>
                  <p>
                    Prices for our health care products are described on our
                    website and are incorporated into these Terms by reference.
                    Every cost will be described in Indian rupees. Prices,
                    products and Services may change at{" "}
                    <a href="https://shramanhealthcare.com/">
                      shramanhealthcare
                    </a>{" "}
                    discretion.
                  </p>
                  <h2>11. Shipping</h2>
                  <p>
                    Shraman Health Care ensures to ship items with a minimum one
                    month before the product expires
                  </p>
                  <h2>12. Termination</h2>
                  <p>
                    Shraman Health Care ensures to ship items with a minimum one
                    month before the product expires
                  </p>
                  <p>
                    <b>(a)</b>{" "}
                    <a href="https://shramanhealthcare.com/">
                      shramanhealthcare
                    </a>{" "}
                    may terminate, full period or short period, your use of the
                    website or any of its services if it believes, in its sole
                    and absolute foresight that you have violated, or
                    unethically manipulated or exploited any of these Terms.
                  </p>
                  <p>
                    <b>(b)</b> Notwithstanding Section 14.a above, these Terms
                    will survive indefinitely unless and until{" "}
                    <a href="https://shramanhealthcare.com/">
                      shramanhealthcare
                    </a>{" "}
                    chooses to terminate them.
                  </p>
                  <p>
                    <b>(c)</b> If you or{" "}
                    <a href="https://shramanhealthcare.com/">
                      shramanhealthcare
                    </a>{" "}
                    terminates your use of the Website or any of its health care
                    services,{" "}
                    <a href="https://shramanhealthcare.com/">
                      shramanhealthcare
                    </a>{" "}
                    may delete any content or other materials which is related
                    to your use of the Service and{" "}
                    <a href="https://shramanhealthcare.com/">
                      shramanhealthcare
                    </a>{" "}
                    will have no liability to you or any third party for doing
                    so.
                  </p>
                  <p>
                    <b>(d)</b> You shall be held responsible to pay for any
                    service or product that you have already ordered before the
                    time of your termination by either party whatsoever. Also,
                    you shall be designated to your royalty payments according
                    to the User License Agreement that has or is legally deemed
                    accrued to you.
                  </p>
                  <h2>13. Governing Law</h2>
                  <p>
                    These terms & conditions are governed by and composed in
                    accordance with the laws of India without reference to
                    disturbance of laws principles and disputes arising in
                    relation
                  </p>
                  <h2>14. Severability</h2>
                  <p>
                    If any agreement of the Terms is established to be invalid
                    or unenforceable in part or in whole, such invalidity or
                    unenforceability shall attach only to such agreements or
                    part of such agreements and the remaining part of such
                    agreement and all other agreements of these Terms shall
                    continue to be in full force and effect.
                  </p>
                  <h2>15. Report Abuse</h2>
                  <p>
                    Users are solely held accountable for every data uploaded on
                    to the Website. Users can be held legally liable for their
                    uploaded data and may be held legally accountable if that
                    information includes, for example, comments that are
                    defamatory or material which are protected by copyright,
                    trademark, etc. If you come across any abuse or violation of
                    these Terms, please report at{" "}
                    <a href="https://shramanhealthcare.com/">our website</a>. ,{" "}
                    with Subject heading “Report Abuse”.
                  </p>
                  <h2>16. Returns & Refund</h2>
                  <p>
                    <strong>I. Return To Origin-</strong>
                    <ul>
                      <li>
                        a). When a product is not delivered by courier due to
                        some reason which is not certain, Shraman Health Care
                        will refund the full amount after receiving the product
                        at our facility.
                      </li>
                      <li>
                        b). When a product is lost by courier during the
                        process. We will refund the full amount at the same
                        time.
                      </li>
                    </ul>
                    <strong>II. Customer returns</strong>
                    <ul>
                      <li>
                        a). Customers are liable to request a return only when a
                        product delivered is wrong or damaged.
                      </li>
                      <li>
                        b). Customers can contact Arogyam’s support team via
                        email. Our team will request certain proofs of ‘damages’
                        or ‘wrong item’.
                      </li>
                    </ul>
                  </p>
                </div>
              </div>
              <div className="col-sm-4 col-12">
                <div className="refundimg  animate__animated animate__fadeInDown">
                  <img
                    src="refundpolicy.jpg"
                    className="img-fluid"
                    alt="refund policy"
                  />
                </div>
              </div>
            </div>
          ) : null}

          {/* hindi section goes here */}

          {lang === "hi" ? (
            <div className="row">
              <div className="col-md-8  animate__animated animate__fadeInDown">
                <div class="container">
                  <h1>नियम और शर्तें – शर्मन हेल्थ केयर</h1>
                  <p>
                    कृपया निम्नलिखित नियम और शर्तों को ध्यान से पढ़ें क्योंकि
                    आपकी सेवा का उपयोग निम्नलिखित नियम और शर्तों की आपकी
                    स्वीकृति और उनके अनुरूप होने के अधीन है। (“नियम”)।
                  </p>
                  <p>
                    हमारी किसी भी सेवा के उपयोग को स्वीकार करके, आप सहमत हैं कि
                    आपने हमारे नियमों और शर्तों को ध्यान से पढ़ा और समझा है और
                    इन शर्तों का पालन करने के लिए बाध्य हैं, भले ही आप हमारी
                    सेवाओं का उपयोग कैसे भी करें। ये नियम और नीतियाँ सूचना
                    प्रौद्योगिकी अधिनियम 2000 के तहत सूचना प्रौद्योगिकी (मध्यस्थ
                    दिशा-निर्देश) नियम, 2011 के प्रावधानों के अनुसार हैं। इन
                    शर्तों में, "आप", "उपयोगकर्ता" का अर्थ वेबसाइट तक पहुँचने
                    वाले अंतिम उपयोगकर्ता से होगा, और "हम", "हमें" और "हमारा" का
                    अर्थ शर्मन हेल्थ केयर और उसके सहयोगी होंगे।
                  </p>
                  <h2>1.परिचय</h2>
                  <p>
                    <a href="https://shramanhealthcare.com/">
                    शर्मनहेल्थकेयर
                    </a>{" "}
                    शर्मन हेल्थ केयर द्वारा लाइसेंस प्राप्त एक ई-कॉमर्स
                    हेल्थकेयर पोर्टल है, जो भारत के कानूनों के तहत निगमित एक
                    स्वास्थ्य देखभाल कंपनी है।
                  </p>
                  <p>
                    वेबसाइट का उपयोग आपको सभी नियमों और शर्तों के साथ-साथ इन
                    शर्तों में निहित सूचनाओं को बिना किसी संशोधन के स्वीकार करने
                    की शर्त पर प्रदान किया जाता है, जैसा कि समय-समय पर वेबसाइट
                    पर पोस्ट किया जा सकता है।{" "}
                    <a href="https://shramanhealthcare.com/">
                    शर्मनहेल्थकेयर
                    </a>{" "}
                    अपने विवेकानुसार किसी भी उपयोगकर्ता को बिना कोई कारण बताए
                    वेबसाइट पर पंजीकरण करने से मना करने का अधिकार सुरक्षित रखता
                    है।
                  </p>
                  <h2>2.उपयोगकर्ता खाता, पासवर्ड और सुरक्षा</h2>
                  <p>
                    वेबसाइट की पंजीकरण प्रक्रिया पूरी करने पर, आपको एक पासवर्ड
                    और खाता जानकारी प्राप्त होगी। आप खाते और पासवर्ड की गोपनीयता
                    के लिए पूरी तरह से जिम्मेदार होंगे और आपके पासवर्ड या खाते
                    के तहत होने वाली सभी गतिविधियों के लिए पूरी तरह से जिम्मेदार
                    होंगे।{" "}
                    <a href="https://shramanhealthcare.com/">
                    शर्मनहेल्थकेयर
                    </a>{" "}
                    इस अनुभाग का अनुपालन करने में आपकी विफलता से उत्पन्न होने
                    वाले किसी भी नुकसान या क्षति के लिए उत्तरदायी नहीं होगा।
                  </p>
                  <h2>3. दी जाने वाली सेवाएं</h2>
                  <p>
                    <a href="https://shramanhealthcare.com/">
                    शर्मनहेल्थकेयर
                    </a>{" "}
                    वेबसाइट के माध्यम से कई स्वास्थ्य देखभाल सेवाएँ प्रदान करता
                    है (ऐसी सभी सेवाएँ, सामूहिक रूप से, "सेवा")। ऐसी एक सेवा
                    उपयोगकर्ताओं को शर्मन हेल्थ केयर और उसके सहयोगियों से मूल
                    हर्बल उत्पाद खरीदने में सक्षम बनाती है। उपयोगकर्ता वेबसाइट
                    के माध्यम से विभिन्न भुगतान विधियों के माध्यम से उत्पादों को
                    खरीद सकता है। उत्पादों की बिक्री और खरीद बिक्री की विशिष्ट
                    नीतियों, जैसे वापसी नीति, रद्दीकरण नीति, आदि द्वारा अतिरिक्त
                    रूप से शासित होगी।
                  </p>
                  <h2>4. गोपनीयता नीति</h2>
                  <p>
                    उपयोगकर्ता इस बात पर सहमति देता है, व्यक्त करता है और सहमत
                    है कि उसने गोपनीयता नीति को ध्यान से पढ़ा है और अब पूरी तरह
                    से समझता है{" "}
                    <a href="https://shramanhealthcare.com/">Privacy Policy</a>.
                    उपयोगकर्ता यह भी स्वीकार करता है कि ऐसी गोपनीयता नीति की
                    शर्तें और सामग्री उसके लिए स्वीकार्य हैं।
                  </p>
                  <h2>5. सीमित उपयोगकर्ता</h2>
                  <p>
                    उपयोगकर्ता सहमत है और वचनबद्ध है कि वह वेबसाइट से प्राप्त
                    किसी भी जानकारी या सॉफ़्टवेयर को रिवर्स इंजीनियर नहीं करेगा,
                    संशोधित नहीं करेगा, प्रकाशित नहीं करेगा, लाइसेंस नहीं देगा,
                    व्युत्पन्न कार्य नहीं बनाएगा, स्थानांतरित नहीं करेगा, कॉपी
                    नहीं करेगा, वितरित नहीं करेगा, संचारित नहीं करेगा, प्रदर्शित
                    नहीं करेगा, डाउनलोड नहीं करेगा, निष्पादित नहीं करेगा,
                    पुनरुत्पादित नहीं करेगा या नहीं बेचेगा। वेबसाइट की सामग्री
                    का सीमित पुनरुत्पादन और प्रतिलिपि बनाना अनुमत है बशर्ते कि
                    शरमन हेल्थ केयर का नाम स्रोत के रूप में बताया गया हो और
                    पूर्व लिखित अनुमति हो
                    <a href="https://shramanhealthcare.com/">
                    शर्मनहेल्थकेयर
                    </a>{" "}
                    संदेह को दूर करने के लिए, वाणिज्यिक या गैर-वाणिज्यिक
                    उद्देश्यों के लिए सामग्री का पुनरुत्पादन, प्रतिलिपि बनाना और
                    वेबसाइट की सामग्री के भीतर डेटा और सूचना के अनुचित संशोधन की
                    अनुमति नहीं है।
                  </p>
                  <h2>6. उपयोगकर्ता आचरण और नियम</h2>
                  <p>
                    {" "}
                    आप सहमत हैं और इसका उपयोग करने का वचन देते हैं{" "}
                    <a href="https://shramanhealthcare.com/">
                    शर्मनहेल्थकेयर
                    </a>{" "}
                    केवल उचित संदेश और सामग्री पोस्ट और अपलोड करें। आप यह भी
                    स्वीकार करते हैं कि आप सहमत हैं, और किसी सीमा के रूप में
                    नहीं, आप यह वचन देते हैं कि सेवा का उपयोग करते समय, आप निम्न
                    कार्य नहीं करेंगे:
                  </p>
                  <ul>
                    <li>
                      दूसरों को बदनाम करना या अन्यथा उनके कानूनी अधिकारों का
                      उल्लंघन करना।
                    </li>
                    <li>
                      कोई भी अनुचित, अभद्र या गैरकानूनी विषय, नाम और सामग्री या
                      कोई भी जानकारी प्रकाशित या पोस्ट करना जिससे कोई भी संबंधित
                      न हो;
                    </li>
                    <li>
                      बौद्धिक संपदा कानूनों द्वारा संरक्षित कोई भी फ़ाइल अपलोड न
                      करें, जब तक कि आपके पास उसके अधिकार न हों या आपने सभी
                      आवश्यक सहमति एकत्र न कर ली हो;
                    </li>
                    <li>
                      दूषित फ़ाइलें या कोई अन्य समान सॉफ़्टवेयर या प्रोग्राम
                      अपलोड या वितरित न करें जो वेबसाइट या किसी उपयोगकर्ता के
                      कंप्यूटर के संचालन को नुकसान पहुंचा सकता है।
                    </li>
                    <li>
                      सर्वेक्षण, प्रतियोगिताएं या श्रृंखला पत्र आयोजित करना या
                      अग्रेषित करना;{" "}
                    </li>
                    <li>
                      अपलोड की गई फ़ाइल में निहित किसी भी लेखक के आरोप, कानूनी
                      या लेबल मूल या अन्य उचित नोटिस या सॉफ़्टवेयर या मालिकाना
                      पदनाम या अन्य सामग्री के स्रोत को गलत साबित करना या हटाना;
                    </li>
                    <li>
                      किसी भी दिशा-निर्देश या आचार संहिता का उल्लंघन करना, जो
                      किसी विशेष सेवा के लिए उपयोगी हो सकता है;
                    </li>
                    <li>
                      भारत में या उसके बाहर वर्तमान में लागू किसी भी विनियमन या
                      लागू कानून का उल्लंघन करना; और
                    </li>
                    <li>
                      इन नियमों और शर्तों या वेबसाइट के उपयोग के लिए किसी अन्य
                      नियम और शर्तों का अनैतिक रूप से हेरफेर या शोषण करना जो कि
                      उचित नहीं है{" "}
                      <a href="https://shramanhealthcare.com/">
                      शर्मनहेल्थकेयर
                      </a>{" "}
                    </li>
                  </ul>
                  <h2>उपयोगकर्ता वारंटी और प्रतिनिधित्व :</h2>
                  <p>
                    उपयोगकर्ता गारंटी देता है, वारंट करता है, और स्वीकृति देता
                    है कि आप उस सामग्री के स्वामी हैं जिसे आप सबमिट करते हैं या
                    अन्यथा सामग्री का उपयोग करने के लिए अधिकृत हैं और यह सामग्री
                    दूसरों की संपत्ति या बौद्धिक संपदा अधिकारों या किसी अन्य
                    अधिकार का उल्लंघन नहीं करती है। आप यह भी मान्यता लेते हैं कि
                    आपके ज्ञान के अनुसार, किसी भी सामग्री से संबंधित कोई
                    कार्रवाई, मुकदमा, कार्यवाही या जांच की धमकी नहीं दी गई है,
                    जिसमें ट्रेडमार्क, कॉपीराइट और व्यापार नाम सेवा चिह्न शामिल
                    हैं, जो आपके द्वारा पूर्व में या वर्तमान में उपयोग किए गए
                    हैं।{" "}
                    <a href="https://shramanhealthcare.com/">
                    शर्मनहेल्थकेयर
                    </a>{" "}
                  </p>
                  <h2>7. बौद्धिक संपदा अधिकार</h2>
                  <p>
                    <b>ए)</b> जब तक अन्यथा संकेत न दिया गया हो या कोई भी
                    विरोधाभासी या स्वामित्व वाली सामग्री किसी तीसरे पक्ष द्वारा
                    नियंत्रित या स्वामित्व में न हो और ऐसा स्पष्ट रूप से उल्लेख
                    न किया गया हो,{" "}
                    <a href="https://shramanhealthcare.com/">
                    शर्मनहेल्थकेयर
                    </a>{" "}
                    उल्लिखित वेबसाइट के सभी बौद्धिक संपदा अधिकारों का स्वामी है,
                    जिसमें बिना किसी सीमा के, कोई भी और सभी अधिकार, उपयोगिता
                    मॉडल, ट्रेडमार्क और व्यापार नाम, सेवा चिह्न, कॉपीराइट,
                    संबंधित अधिकार, पेटेंट, डिज़ाइन, तकनीकी जानकारी, व्यापार
                    रहस्य और आविष्कार (पेटेंट योग्य हो या न हो), सद्भावना, स्रोत
                    कोड, मेटा टैग, डेटाबेस, पाठ, सामग्री, ग्राफिक्स, आइकन और
                    हाइपरलिंक शामिल हैं। आप सहमत हैं कि आप वेबसाइट से किसी भी
                    सामग्री का उपयोग, नकल या प्रसार नहीं करेंगे{" "}
                    <a href="https://shramanhealthcare.com/">
                    शर्मनहेल्थकेयर
                    </a>{" "}
                    बिना प्राधिकरण प्राप्त किए{" "}
                    <a href="https://shramanhealthcare.com/">
                    शर्मनहेल्थकेयर
                    </a>{" "}
                    .
                  </p>
                  <p>
                    <b>बी)</b> पूर्वगामी के बावजूद, यह स्पष्ट रूप से स्पष्ट है
                    कि आप स्वामित्व को पहचानेंगे और किसी भी सेवा का उपयोग करते
                    समय आपके द्वारा व्यवस्थित या अपलोड की गई किसी भी सामग्री के
                    लिए पूरी तरह उत्तरदायी होंगे, जिसमें छवियों, तस्वीरों,
                    संगीत, ध्वनि या वीडियो के माध्यम से कोई भी डेटा या किसी भी
                    प्रकार की जानकारी शामिल है, जिसे आप हमारी विभिन्न स्वास्थ्य
                    देखभाल सेवाओं का उपयोग करते समय अपलोड, संचारित या संग्रहीत
                    कर सकते हैं। हालाँकि, उत्पाद अनुकूलन सेवा के संबंध में, आप
                    यह भी सहमत हैं कि सामग्री को अपलोड और पोस्ट करके{" "}
                    <a href="https://shramanhealthcare.com/">
                    शर्मनहेल्थकेयर
                    </a>{" "}
                    सार्वजनिक रूप से देखने और तीसरे पक्ष के उपयोगकर्ताओं द्वारा
                    आपकी सामग्री के दोहराव या उपयोग के लिए, आप उपयोगकर्ता को
                    स्वीकार करते हैं जिसके द्वारा आप इसके उपयोग के लिए एक
                    गैर-अनन्य लाइसेंस प्रदान करते हैं।
                  </p>
                  <h2>8. वारंटी का अस्वीकरण/दायित्व की सीमा</h2>
                  <p>
                    {" "}
                    <a href="https://shramanhealthcare.com/">
                    शर्मनहेल्थकेयर
                    </a>{" "}
                    यह सुनिश्चित किया है कि हमारी वेबसाइट पर सभी जानकारी सही है,{" "}
                    <a href="https://shramanhealthcare.com/">
                    शर्मनहेल्थकेयर
                    </a>{" "}
                    किसी भी डेटा, सूचना, उत्पाद या सेवा की गुणवत्ता, निश्चितता
                    या पूर्णता के बारे में न तो लाइसेंस देता है और न ही कोई
                    चित्रण करता है। किसी भी स्थिति में{" "}
                    <a href="https://shramanhealthcare.com/">
                    शर्मनहेल्थकेयर
                    </a>{" "}
                    किसी भी प्रत्यक्ष, अप्रत्यक्ष, अनुशासनात्मक, आकस्मिक, विशेष,
                    परिणामी क्षति या किसी अन्य क्षति के लिए उत्तरदायी नहीं होगा
                    जो निम्न से उत्पन्न हो:
                  </p>
                  <p>
                    <b>(ए)</b> सेवाओं या उत्पादों का उपयोग करने में असमर्थता या
                    सेवाओं या उत्पादों का उपयोग करने में असमर्थता;
                  </p>
                  <p>
                    <b>(बी)</b> उपयोगकर्ता के प्रसारण या उपयोगकर्ता के डेटा तक
                    अवैध पहुँच या संशोधन;
                  </p>
                  <p>
                    <b>(सी)</b> सेवाओं का वर्णन करने वाला कोई अन्य मामला; जिसमें
                    उपयोग, डेटा या लाभ की हानि के लिए क्षतिपूर्ति शामिल है, और
                    यह इन्हीं तक सीमित नहीं है, जो किसी भी तरह से उपयोग या
                    प्रदर्शन से जुड़ी है।{" "}
                    <a href="https://shramanhealthcare.com/">
                    शर्मनहेल्थकेयर
                    </a>{" "}
                    या इसकी सेवाएँ।{" "}
                    <a href="https://shramanhealthcare.com/">
                    शर्मनहेल्थकेयर
                    </a>{" "}
                    वेबसाइट या उसकी सेवाओं का उपयोग करने में देरी या अक्षमता,
                    सेवाओं की व्यवस्था या उन्हें प्रदान करने में विफलता, या
                    वेबसाइट के माध्यम से प्राप्त किसी भी प्रकार की जानकारी,
                    सॉफ़्टवेयर, उत्पाद, सेवाएँ और संबंधित ग्राफ़िक्स के लिए
                    जिम्मेदार नहीं होगा, या अन्यथा वेबसाइट के उपयोग से उत्पन्न
                    होगा, चाहे वह अनुबंध, टोर्ट, लापरवाही, सख्त देयता या अन्यथा
                    पर आधारित हो। इसके अलावा,{" "}
                    <a href="https://shramanhealthcare.com/">
                    शर्मनहेल्थकेयर
                    </a>{" "}
                    यदि वेबसाइट आवधिक रखरखाव कार्यों के दौरान उपलब्ध नहीं है या
                    तकनीकी कारणों से या किसी अन्य कारण से हमारी वेबसाइट से
                    कनेक्शन का अनियोजित निलंबन है, तो इसके लिए हम जिम्मेदार नहीं
                    होंगे।{" "}
                    <a href="https://shramanhealthcare.com/">
                    शर्मनहेल्थकेयर
                    </a>{" "}
                    उपयोगकर्ता स्वीकार करता है कि वेबसाइट के माध्यम से प्राप्त
                    कोई भी जानकारी या डेटा पूरी तरह से उनकी अपनी दूरदर्शिता और
                    जोखिम पर किया जाता है और वे ऐसी सामग्री और/या डेटा के
                    डाउनलोड के परिणामस्वरूप अपने कंप्यूटर सिस्टम को होने वाली
                    किसी भी क्षति या डेटा की किसी भी हानि के लिए पूरी तरह
                    उत्तरदायी होंगे।
                  </p>
                  <h2>9. प्रीमियम</h2>
                  <p>
                    उपयोगकर्ता बचाव करने के लिए सहमत है आप क्षतिपूर्ति करने,
                    बचाव करने और हानिरहित रखने के लिए सहमत हैं{" "}
                    <a href="https://shramanhealthcare.com/">
                    शर्मनहेल्थकेयर
                    </a>{" "}
                    किसी भी और सभी नुकसानों, ऋण, आरोपों, क्षतियों, लागतों और
                    खर्चों (कानूनी फीस और उससे संबंधित व्यय और उस पर देय ब्याज
                    सहित) के खिलाफ बहस करें या उसके द्वारा उठाए गए{" "}
                    <a href="https://shramanhealthcare.com/">
                    शर्मनहेल्थकेयर
                    </a>{" "}
                    जो इन नियमों और शर्तों से सहमत उपयोगकर्ता द्वारा किए गए किसी
                    भी प्रतिनिधित्व, वारंटी या समझौते या दायित्व के किसी भी
                    उल्लंघन या गैर-निष्पादन के कारण उत्पन्न होते हैं,
                    परिणामस्वरूप या देय हो सकते हैं।
                  </p>
                  <h2>10. मूल्य निर्धारण</h2>
                  <p>
                    हमारे स्वास्थ्य देखभाल उत्पादों की कीमतें हमारी वेबसाइट पर
                    वर्णित हैं और संदर्भ द्वारा इन शर्तों में शामिल की गई हैं।
                    हर लागत का वर्णन भारतीय रुपये में किया जाएगा। कीमतें, उत्पाद
                    और सेवाएँ समय-समय पर बदल सकती हैं{" "}
                    <a href="https://shramanhealthcare.com/">
                    शर्मनहेल्थकेयर
                    </a>{" "}
                    विवेक.
                  </p>
                  <h2>11. शिपिंग</h2>
                  <p>
                    शर्मन हेल्थ केयर उत्पाद की समाप्ति से कम से कम एक महीने पहले
                    आइटम भेजना सुनिश्चित करता है
                  </p>
                  <h2>12. समापन</h2>
                  <p>
                    शर्मन हेल्थ केयर उत्पाद की समाप्ति से कम से कम एक महीने पहले
                    आइटम भेजना सुनिश्चित करता है
                  </p>
                  <p>
                    <b>(a)</b>{" "}
                    <a href="https://shramanhealthcare.com/">
                    शर्मनहेल्थकेयर
                    </a>{" "}
                    यदि वह अपनी पूर्ण दूरदर्शिता के आधार पर यह मानता है कि आपने
                    इनमें से किसी भी नियम का उल्लंघन किया है, या अनैतिक रूप से
                    हेरफेर किया है या उसका शोषण किया है, तो वह आपकी वेबसाइट या
                    इसकी किसी भी सेवा का उपयोग पूर्ण अवधि या अल्प अवधि के लिए
                    समाप्त कर सकता है।
                  </p>
                  <p>
                    <b>(b)</b> उपरोक्त धारा 14.a के बावजूद, ये शर्तें तब तक
                    अनिश्चित काल तक लागू रहेंगी जब तक कि{" "}
                    <a href="https://shramanhealthcare.com/">
                    शर्मनहेल्थकेयर
                    </a>{" "}
                    उन्हें समाप्त करने का विकल्प चुनता है।{" "}
                  </p>
                  <p>
                    <b>(c)</b> यदि आप या{" "}
                    <a href="https://shramanhealthcare.com/">
                    शर्मनहेल्थकेयर
                    </a>{" "}
                    वेबसाइट या इसकी किसी भी स्वास्थ्य सेवा का उपयोग समाप्त कर
                    देता है,{" "}
                    <a href="https://shramanhealthcare.com/">
                      shramanhealthcare
                    </a>{" "}
                    सेवा के आपके उपयोग से संबंधित कोई भी सामग्री या अन्य सामग्री
                    हटा सकता है और{" "}
                    <a href="https://shramanhealthcare.com/">
                    शर्मनहेल्थकेयर
                    </a>{" "}
                    ऐसा करने पर आपके या किसी तीसरे पक्ष के प्रति कोई दायित्व
                    नहीं होगा।
                  </p>
                  <p>
                    <b>(d)</b> आप किसी भी सेवा या उत्पाद के लिए भुगतान करने के
                    लिए जिम्मेदार होंगे जिसे आपने किसी भी पक्ष द्वारा आपकी
                    समाप्ति के समय से पहले ही ऑर्डर कर दिया है। साथ ही, आपको
                    उपयोगकर्ता लाइसेंस समझौते के अनुसार रॉयल्टी भुगतान के लिए
                    नामित किया जाएगा जो कानूनी रूप से आपके लिए अर्जित किया गया
                    है या माना जाता है।
                  </p>
                  <h2>13. शासी कानून</h2>
                  <p>
                    ये नियम एवं शर्तें भारत के कानूनों के अनुसार संचालित और
                    तैयार की गई हैं, बिना किसी संदर्भ के कानूनी सिद्धांतों में
                    गड़बड़ी और इससे संबंधित विवादों के संबंध में
                  </p>
                  <h2>14. विच्छेदनीयता</h2>
                  <p>
                    यदि शर्तों का कोई समझौता आंशिक या पूर्ण रूप से अमान्य या
                    अप्रवर्तनीय पाया जाता है, तो ऐसी अमान्यता या अप्रवर्तनीयता
                    केवल ऐसे समझौतों या ऐसे समझौतों के भाग पर लागू होगी और ऐसे
                    समझौते का शेष भाग और इन शर्तों के सभी अन्य समझौते पूर्ण रूप
                    से लागू और प्रभावी बने रहेंगे।
                  </p>
                  <h2>15. दुरुपयोग होने की सूचना दें</h2>
                  <p>
                    वेबसाइट पर अपलोड किए गए प्रत्येक डेटा के लिए उपयोगकर्ता पूरी
                    तरह उत्तरदायी हैं। उपयोगकर्ता अपने अपलोड किए गए डेटा के लिए
                    कानूनी रूप से उत्तरदायी हो सकते हैं और यदि उस जानकारी में,
                    उदाहरण के लिए, मानहानिकारक टिप्पणियाँ या कॉपीराइट,
                    ट्रेडमार्क आदि द्वारा संरक्षित सामग्री शामिल है, तो उन्हें
                    कानूनी रूप से उत्तरदायी ठहराया जा सकता है। यदि आपको इन
                    शर्तों का कोई दुरुपयोग या उल्लंघन दिखाई देता है, तो कृपया
                    यहाँ रिपोर्ट करें{" "}
                    <a href="https://shramanhealthcare.com/">हमारी वेबसाइट</a>.
                    , विषय शीर्षक “दुरुपयोग की रिपोर्ट करें” के साथ।
                  </p>
                  <h2>16. रिटर्न और रिफंड</h2>
                  <p>
                    <strong>I.मूल पर लौटें-</strong>
                    <ul>
                      <li>
                        a). जब कोई उत्पाद किसी अज्ञात कारण से कूरियर द्वारा
                        वितरित नहीं किया जाता है, तो शर्मन हेल्थ केयर हमारे
                        प्रतिष्ठान में उत्पाद प्राप्त करने के बाद पूरी राशि वापस
                        कर देगा।
                      </li>
                      <li>
                        b). जब प्रक्रिया के दौरान कूरियर द्वारा कोई उत्पाद खो
                        जाता है। हम उसी समय पूरी राशि वापस कर देंगे।
                      </li>
                    </ul>
                    <strong>II. ग्राहकों की वापसी</strong>
                    <ul>
                      <li>
                        a). ग्राहक केवल तभी वापसी का अनुरोध कर सकते हैं जब
                        डिलीवर किया गया उत्पाद गलत या क्षतिग्रस्त हो।
                      </li>
                      <li>
                        b). ग्राहक ईमेल के ज़रिए आरोग्यम की सहायता टीम से संपर्क
                        कर सकते हैं। हमारी टीम ‘नुकसान’ या ‘गलत आइटम’ के कुछ
                        सबूत मांगेगी।
                      </li>
                    </ul>
                  </p>
                </div>
              </div>
              <div className="col-sm-4 col-12">
                <div className="refundimg  animate__animated animate__fadeInDown">
                  <img
                    src="refundpolicy.jpg"
                    className="img-fluid"
                    alt="refund policy"
                  />
                </div>
              </div>
            </div>
          ) : null}

          {/* punjabi content */}

          {lang === "pb" ? (
            <div className="row">
              <div className="col-md-8  animate__animated animate__fadeInDown">
                <div class="container">
                  <h1>ਨਿਯਮ ਅਤੇ ਸ਼ਰਤਾਂ – ਸ਼ਰਮਨ ਹੈਲਥ ਕੇਅਰ</h1>
                  <p>
                    ਕਿਰਪਾ ਕਰਕੇ ਹੇਠ ਲਿਖੇ ਨਿਯਮਾਂ ਅਤੇ ਸ਼ਰਤਾਂ ਨੂੰ ਧਿਆਨ ਨਾਲ ਪੜ੍ਹੋ
                    ਕਿਉਂਕਿ ਤੁਹਾਡੀ ਸੇਵਾ ਦੀ ਵਰਤੋਂ ਤੁਹਾਡੀ ਸਵੀਕ੍ਰਿਤੀ ਅਤੇ ਹੇਠ ਲਿਖੇ
                    ਨਿਯਮਾਂ ਅਤੇ ਸ਼ਰਤਾਂ ਦੀ ਪਾਲਣਾ ਦੇ ਅਧੀਨ ਹੈ ("ਨਿਯਮ").
                  </p>
                  <p>
                    ਸਾਡੀਆਂ ਕਿਸੇ ਵੀ ਸੇਵਾਵਾਂ ਦੀ ਵਰਤੋਂ ਨੂੰ ਸਵੀਕਾਰ ਕਰਕੇ, ਤੁਸੀਂ ਸਹਿਮਤ
                    ਹੁੰਦੇ ਹੋ ਕਿ ਤੁਸੀਂ ਸਾਡੇ ਨਿਯਮਾਂ ਅਤੇ ਸ਼ਰਤਾਂ ਨੂੰ ਧਿਆਨ ਨਾਲ ਪੜ੍ਹਿਆ
                    ਅਤੇ ਸਮਝਿਆ ਹੈ ਅਤੇ ਇਹਨਾਂ ਨਿਯਮਾਂ ਦੀ ਪਾਲਣਾ ਕਰਨ ਲਈ ਪਾਬੰਦ ਹੋ,
                    ਭਾਵੇਂ ਤੁਸੀਂ ਸਾਡੀਆਂ ਸੇਵਾਵਾਂ ਦੀ ਵਰਤੋਂ ਕਿਵੇਂ ਕਰਦੇ ਹੋ। ਇਹ ਨਿਯਮ
                    ਅਤੇ ਨੀਤੀਆਂ ਸੂਚਨਾ ਤਕਨਾਲੋਜੀ ਐਕਟ 2000 ਦੇ ਤਹਿਤ ਸੂਚਨਾ ਤਕਨਾਲੋਜੀ
                    (ਵਿਚੋਲੇ ਦਿਸ਼ਾ-ਨਿਰਦੇਸ਼) ਨਿਯਮਾਂ, 2011 ਦੇ ਉਪਬੰਧਾਂ ਦੇ ਅਨੁਸਾਰ ਹਨ।
                    ਇਹਨਾਂ ਨਿਯਮਾਂ ਵਿੱਚ, “ਤੁਸੀਂ”, “ਉਪਭੋਗਤਾ” ਦੇ ਹਵਾਲਿਆਂ ਦਾ ਅਰਥ
                    ਵੇਬਸਾਈਟ ਤੱਕ ਪਹੁੰਚ ਕਰਨ ਵਾਲਾ ਅੰਤਮ ਉਪਭੋਗਤਾ ਹੋਵੇਗਾ, ਅਤੇ “ਅਸੀਂ”,
                    “ਅਸੀਂ” ਅਤੇ “ਸਾਡੇ” ਦਾ ਅਰਥ ਸ਼ਰਮਨ ਹੈਲਥ ਕੇਅਰ ਅਤੇ ਇਸਦੇ ਸਹਿਯੋਗੀ
                    ਹੋਣਗੇ।
                  </p>
                  <h2>1. ਜਾਣ-ਪਛਾਣ</h2>
                  <p>
                    <a href="https://shramanhealthcare.com/">
                      Shraman Health Care
                    </a>{" "}
                    ਇੱਕ ਈ-ਕਾਮਰਸ ਹੈਲਥਕੇਅਰ ਪੋਰਟਲ ਹੈ ਜੋ ਸ਼ਰਮਨ ਦੁਆਰਾ ਲਾਇਸੰਸਸ਼ੁਦਾ ਹੈ
                    ਹੈਲਥ ਕੇਅਰ, ਇੱਕ ਸਿਹਤ ਸੰਭਾਲ ਕੰਪਨੀ ਜੋ ਭਾਰਤ ਦੇ ਕਾਨੂੰਨਾਂ ਅਧੀਨ
                    ਸ਼ਾਮਲ ਹੈ।
                  </p>
                  <p>
                    ਵੈੱਬਸਾਈਟ ਦੀ ਵਰਤੋਂ ਤੁਹਾਨੂੰ ਸਾਰੀਆਂ ਸ਼ਰਤਾਂ ਅਤੇ ਸ਼ਰਤਾਂ ਦੇ
                    ਨਾਲ-ਨਾਲ ਇਹਨਾਂ ਨਿਯਮਾਂ ਵਿੱਚ ਸ਼ਾਮਲ ਨੋਟਿਸਾਂ ਵਿੱਚ ਸੋਧ ਕੀਤੇ ਬਿਨਾਂ
                    ਸਵੀਕ੍ਰਿਤੀ ਦੀ ਸ਼ਰਤ 'ਤੇ ਕੀਤੀ ਜਾਂਦੀ ਹੈ, ਜਿਵੇਂ ਕਿ ਸਮੇਂ-ਸਮੇਂ 'ਤੇ
                    ਵੈੱਬਸਾਈਟ 'ਤੇ ਪੋਸਟ ਕੀਤਾ ਜਾ ਸਕਦਾ ਹੈ।{" "}
                    <a href="https://shramanhealthcare.com/">
                    ਸ਼ਰਮਨਹੈਲਥਕੇਅਰ
                    </a>{" "}
                    ਆਪਣੇ ਵਿਵੇਕ ਅਨੁਸਾਰ, ਕਿਸੇ ਉਪਭੋਗਤਾ ਨੂੰ ਵੈੱਬਸਾਈਟ 'ਤੇ ਰਜਿਸਟਰ ਕਰਨ
                    ਤੋਂ ਬਿਨਾਂ ਕੋਈ ਕਾਰਨ ਦੱਸੇ ਸਵੀਕਾਰ ਨਾ ਕਰਨ ਦਾ ਅਧਿਕਾਰ ਰਾਖਵਾਂ ਰੱਖਦਾ
                    ਹੈ।.
                  </p>
                  <h2>2.ਯੂਜ਼ਰ ਖਾਤਾ, ਪਾਸਵਰਡ, ਅਤੇ ਸੁਰੱਖਿਆ</h2>
                  <p>
                    ਵੈੱਬਸਾਈਟ ਦੀ ਰਜਿਸਟ੍ਰੇਸ਼ਨ ਪ੍ਰਕਿਰਿਆ ਪੂਰੀ ਕਰਨ 'ਤੇ, ਤੁਹਾਨੂੰ ਇੱਕ
                    ਪਾਸਵਰਡ ਅਤੇ ਖਾਤੇ ਦੀ ਜਾਣਕਾਰੀ ਪ੍ਰਾਪਤ ਹੋਵੇਗੀ। ਤੁਸੀਂ ਖਾਤੇ ਅਤੇ
                    ਪਾਸਵਰਡ ਦੀ ਗੁਪਤਤਾ ਲਈ ਪੂਰੀ ਤਰ੍ਹਾਂ ਜ਼ਿੰਮੇਵਾਰ ਹੋਵੋਗੇ ਅਤੇ ਤੁਹਾਡੇ
                    ਪਾਸਵਰਡ ਜਾਂ ਖਾਤੇ ਦੇ ਅਧੀਨ ਹੋਣ ਵਾਲੀਆਂ ਸਾਰੀਆਂ ਗਤੀਵਿਧੀਆਂ ਲਈ ਪੂਰੀ
                    ਤਰ੍ਹਾਂ ਜ਼ਿੰਮੇਵਾਰ ਹੋਵੋਗੇ।{" "}
                    <a href="https://shramanhealthcare.com/">
                    ਸ਼ਰਮਨਹੈਲਥਕੇਅਰ
                    </a>{" "}
                    ਇਸ ਧਾਰਾ ਦੀ ਪਾਲਣਾ ਕਰਨ ਵਿੱਚ ਤੁਹਾਡੀ ਅਸਫਲਤਾ ਕਾਰਨ ਹੋਣ ਵਾਲੇ ਕਿਸੇ
                    ਵੀ ਨੁਕਸਾਨ ਜਾਂ ਨੁਕਸਾਨ ਲਈ ਜ਼ਿੰਮੇਵਾਰ ਨਹੀਂ ਹੋ ਸਕਦਾ ਅਤੇ ਨਾ ਹੀ
                    ਹੋਵੇਗਾ।
                  </p>
                  <h2>3. ਪੇਸ਼ ਕੀਤੀਆਂ ਜਾਂਦੀਆਂ ਸੇਵਾਵਾਂ</h2>
                  <p>
                    <a href="https://shramanhealthcare.com/">
                    ਸ਼ਰਮਨਹੈਲਥਕੇਅਰ
                    </a>{" "}
                    ਵੈੱਬ ਸਾਈਟ (ਸਾਰੀਆਂ ਅਜਿਹੀਆਂ ਸੇਵਾਵਾਂ, ਸਮੂਹਿਕ ਤੌਰ 'ਤੇ, "ਸੇਵਾ")
                    ਰਾਹੀਂ ਕਈ ਸਿਹਤ ਸੰਭਾਲ ਸੇਵਾਵਾਂ ਪ੍ਰਦਾਨ ਕਰਦਾ ਹੈ। ਇੱਕ ਅਜਿਹੀ ਸੇਵਾ
                    ਉਪਭੋਗਤਾਵਾਂ ਨੂੰ ਸ਼ਰਮਨ ਹੈਲਥ ਕੇਅਰ ਅਤੇ ਇਸਦੇ ਸਹਿਯੋਗੀਆਂ ਤੋਂ ਅਸਲੀ
                    ਜੜੀ-ਬੂਟੀਆਂ ਦੇ ਉਤਪਾਦ ਖਰੀਦਣ ਦੇ ਯੋਗ ਬਣਾਉਂਦੀ ਹੈ। ਉਪਭੋਗਤਾ
                    ਵੈੱਬਸਾਈਟ ਰਾਹੀਂ ਪ੍ਰਦਾਨ ਕੀਤੇ ਗਏ ਭੁਗਤਾਨਾਂ ਦੇ ਵੱਖ-ਵੱਖ ਤਰੀਕਿਆਂ
                    ਰਾਹੀਂ ਉਤਪਾਦਾਂ ਨੂੰ ਖਰੀਦ ਸਕਦਾ ਹੈ। ਉਤਪਾਦਾਂ ਦੀ ਵਿਕਰੀ ਅਤੇ ਖਰੀਦ ਇਸ
                    ਤੋਂ ਇਲਾਵਾ ਵਿਕਰੀ ਦੀਆਂ ਖਾਸ ਨੀਤੀਆਂ, ਜਿਵੇਂ ਕਿ ਵਾਪਸੀ ਨੀਤੀ, ਰੱਦ
                    ਕਰਨ ਦੀ ਨੀਤੀ, ਆਦਿ ਦੁਆਰਾ ਨਿਯੰਤਰਿਤ ਕੀਤੀ ਜਾਵੇਗੀ।
                  </p>
                  <h2>4. ਪਰਾਈਵੇਟ ਨੀਤੀ</h2>
                  <p>
                    ਉਪਭੋਗਤਾ ਇਸ ਦੁਆਰਾ ਸਹਿਮਤੀ ਦਿੰਦਾ ਹੈ, ਪ੍ਰਗਟ ਕਰਦਾ ਹੈ ਅਤੇ ਸਹਿਮਤ
                    ਹੁੰਦਾ ਹੈ ਕਿ ਉਸਨੇ ਧਿਆਨ ਨਾਲ ਪੜ੍ਹਿਆ ਹੈ ਅਤੇ ਹੁਣ ਗੋਪਨੀਯਤਾ ਨੀਤੀ
                    ਨੂੰ ਪੂਰੀ ਤਰ੍ਹਾਂ ਸਮਝ ਲਿਆ ਹੈ{" "}
                    <a href="https://shramanhealthcare.com/">Privacy Policy</a>.
                    ਉਪਭੋਗਤਾ ਇਹ ਵੀ ਸਵੀਕਾਰ ਕਰਦਾ ਹੈ ਕਿ ਅਜਿਹੀ ਗੋਪਨੀਯਤਾ ਨੀਤੀ ਦੀਆਂ
                    ਸ਼ਰਤਾਂ ਅਤੇ ਸਮੱਗਰੀ ਉਸਨੂੰ ਸਵੀਕਾਰ ਹਨ।
                  </p>
                  <h2>5. ਸੀਮਤ ਵਰਤੋਂਕਾਰ</h2>
                  <p>
                    ਉਪਭੋਗਤਾ ਸਹਿਮਤ ਹੈ ਅਤੇ ਇਹ ਸਵੀਕਾਰ ਕਰਦਾ ਹੈ ਕਿ ਵੈੱਬਸਾਈਟ ਤੋਂ
                    ਪ੍ਰਾਪਤ ਕੀਤੀ ਗਈ ਕਿਸੇ ਵੀ ਜਾਣਕਾਰੀ ਜਾਂ ਸੌਫਟਵੇਅਰ ਨੂੰ ਉਲਟਾ
                    ਇੰਜੀਨੀਅਰ, ਸੋਧ, ਪ੍ਰਕਾਸ਼ਨ, ਲਾਇਸੈਂਸ, ਡੈਰੀਵੇਟਿਵ ਕੰਮ ਬਣਾਉਣ,
                    ਟ੍ਰਾਂਸਫਰ, ਕਾਪੀ, ਵੰਡ, ਪ੍ਰਸਾਰਿਤ, ਪ੍ਰਦਰਸ਼ਿਤ, ਡਾਊਨਲੋਡ, ਪ੍ਰਦਰਸ਼ਨ,
                    ਦੁਬਾਰਾ ਪੈਦਾ ਕਰਨ ਜਾਂ ਵੇਚਣ ਦੀ ਆਗਿਆ ਨਹੀਂ ਹੈ। ਵੈੱਬਸਾਈਟ ਦੀ ਸਮੱਗਰੀ
                    ਦੀ ਸੀਮਤ ਪ੍ਰਜਨਨ ਅਤੇ ਕਾਪੀ ਦੀ ਇਜਾਜ਼ਤ ਹੈ ਬਸ਼ਰਤੇ ਸ਼ਰਮਨ ਹੈਲਥ ਕੇਅਰ
                    ਦਾ ਨਾਮ ਸਰੋਤ ਵਜੋਂ ਦੱਸਿਆ ਗਿਆ ਹੋਵੇ ਅਤੇ ਪਹਿਲਾਂ ਲਿਖਤੀ ਇਜਾਜ਼ਤ
                    <a href="https://shramanhealthcare.com/">
                    ਸ਼ਰਮਨਹੈਲਥਕੇਅਰ
                    </a>{" "}
                    ਦੀ ਮੰਗ ਕੀਤੀ ਜਾਂਦੀ ਹੈ। ਸ਼ੱਕ ਨੂੰ ਦੂਰ ਕਰਨ ਲਈ, ਵਪਾਰਕ ਜਾਂ
                    ਗੈਰ-ਵਪਾਰਕ ਉਦੇਸ਼ਾਂ ਲਈ ਸਮੱਗਰੀ ਦੀ ਪ੍ਰਜਨਨ, ਨਕਲ ਅਤੇ ਵੈੱਬਸਾਈਟ ਦੀ
                    ਸਮੱਗਰੀ ਦੇ ਅੰਦਰ ਡੇਟਾ ਅਤੇ ਜਾਣਕਾਰੀ ਦੀ ਬੇਲੋੜੀ ਸੋਧ ਦੀ ਆਗਿਆ ਨਹੀਂ
                    ਹੈ।
                  </p>
                  <h2>6. ਉਪਭੋਗਤਾ ਆਚਰਣ ਅਤੇ ਨਿਯਮ</h2>
                  <p>
                    {" "}
                    ਤੁਸੀਂ ਸਹਿਮਤ ਹੁੰਦੇ ਹੋ ਅਤੇ ਇਸਦੀ ਵਰਤੋਂ ਕਰਨ ਦਾ ਵਾਅਦਾ ਕਰਦੇ ਹੋ
                    <a href="https://shramanhealthcare.com/">
                    ਸ਼ਰਮਨਹੈਲਥਕੇਅਰ
                    </a>{" "}
                    ਸਿਰਫ਼ ਉਹ ਸੁਨੇਹੇ ਅਤੇ ਸਮੱਗਰੀ ਪੋਸਟ ਅਤੇ ਅਪਲੋਡ ਕਰਨ ਲਈ ਜੋ ਉਚਿਤ ਹਨ।
                    ਤੁਸੀਂ ਸਹਿਮਤ ਹੋਣ ਲਈ ਵੀ ਸਹਿਮਤ ਹੁੰਦੇ ਹੋ, ਅਤੇ ਇੱਕ ਸੀਮਾ ਵਜੋਂ
                    ਨਹੀਂ, ਤੁਸੀਂ ਇਹ ਸਵੀਕਾਰ ਕਰਦੇ ਹੋ ਕਿ ਸੇਵਾ ਦੀ ਵਰਤੋਂ ਕਰਦੇ ਸਮੇਂ,
                    ਤੁਸੀਂ ਇਹ ਨਹੀਂ ਕਰੋਗੇ:
                  </p>
                  <ul>
                    <li>
                      ਦੂਜਿਆਂ ਦੇ ਕਿਸੇ ਵੀ ਕਾਨੂੰਨੀ ਅਧਿਕਾਰ ਨੂੰ ਬਦਨਾਮ ਕਰਨਾ ਜਾਂ ਕਿਸੇ
                      ਹੋਰ ਤਰ੍ਹਾਂ ਉਲੰਘਣਾ ਕਰਨਾ।
                    </li>
                    <li>
                      ਕੋਈ ਵੀ ਅਣਉਚਿਤ, ਅਸ਼ਲੀਲ ਜਾਂ ਗੈਰ-ਕਾਨੂੰਨੀ ਵਿਸ਼ਾ, ਨਾਮ ਅਤੇ
                      ਸਮੱਗਰੀ ਜਾਂ ਕੋਈ ਵੀ ਜਾਣਕਾਰੀ ਪ੍ਰਕਾਸ਼ਿਤ ਜਾਂ ਪੋਸਟ ਕਰਨਾ ਜਿਸ ਨਾਲ
                      ਕੋਈ ਵੀ ਸਬੰਧਤ ਨਾ ਹੋਵੇ;
                    </li>
                    <li>
                      ਕੋਈ ਵੀ ਫਾਈਲ ਅਪਲੋਡ ਕਰੋ ਜੋ ਬੌਧਿਕ ਸੰਪਤੀ ਕਾਨੂੰਨਾਂ ਦੁਆਰਾ
                      ਸੁਰੱਖਿਅਤ ਹੈ, ਜਦੋਂ ਤੱਕ ਕਿ ਤੁਹਾਡੇ ਕੋਲ ਇਸਦੇ ਅਧਿਕਾਰ ਨਹੀਂ ਹਨ
                      ਜਾਂ ਤੁਸੀਂ ਸਾਰੀਆਂ ਜ਼ਰੂਰੀ ਸਹਿਮਤੀਆਂ ਇਕੱਠੀਆਂ ਨਹੀਂ ਕੀਤੀਆਂ ਹਨ;
                    </li>
                    <li>
                      ਖਰਾਬ ਫਾਈਲਾਂ, ਜਾਂ ਕੋਈ ਹੋਰ ਸਮਾਨ ਸਾਫਟਵੇਅਰ ਜਾਂ ਪ੍ਰੋਗਰਾਮ ਜੋ
                      ਵੈੱਬਸਾਈਟ ਜਾਂ ਕਿਸੇ ਵੀ ਉਪਭੋਗਤਾ ਦੇ ਕੰਪਿਊਟਰ ਦੇ ਸੰਚਾਲਨ ਨੂੰ
                      ਨੁਕਸਾਨ ਪਹੁੰਚਾ ਸਕਦੇ ਹਨ, ਨੂੰ ਅੱਪਲੋਡ ਜਾਂ ਵੰਡੋ।
                    </li>
                    <li>
                      ਸਰਵੇਖਣ, ਮੁਕਾਬਲੇ ਜਾਂ ਚੇਨ ਲੈਟਰ ਕਰਵਾਉਣਾ ਜਾਂ ਅੱਗੇ ਭੇਜਣਾ;{" "}
                    </li>
                    <li>
                      ਕਿਸੇ ਵੀ ਲੇਖਕ ਵਿਸ਼ੇਸ਼ਤਾ, ਕਾਨੂੰਨੀ ਜਾਂ ਮੂਲ ਲੇਬਲ ਜਾਂ ਹੋਰ ਉਚਿਤ
                      ਨੋਟਿਸ ਜਾਂ ਸਰੋਤ ਸਾਫਟਵੇਅਰ ਜਾਂ ਮਲਕੀਅਤ ਅਹੁਦਿਆਂ ਜਾਂ ਹੋਰ ਸਮੱਗਰੀ
                      ਨੂੰ ਝੂਠਾ ਸਾਬਤ ਕਰਨਾ ਜਾਂ ਮਿਟਾਉਣਾ ਜੋ ਅਪਲੋਡ ਕੀਤੀ ਗਈ ਫਾਈਲ ਵਿੱਚ
                      ਸ਼ਾਮਲ ਹੈ;
                    </li>
                    <li>
                      ਕਿਸੇ ਵੀ ਦਿਸ਼ਾ-ਨਿਰਦੇਸ਼ਾਂ ਜਾਂ ਆਚਾਰ ਸੰਹਿਤਾ ਦੀ ਉਲੰਘਣਾ ਕਰਨਾ, ਜੋ
                      ਕਿ ਕਿਸੇ ਖਾਸ ਸੇਵਾ ਲਈ ਲਾਭਦਾਇਕ ਹੋ ਸਕਦਾ ਹੈ;
                    </li>
                    <li>
                      ਭਾਰਤ ਵਿੱਚ ਜਾਂ ਬਾਹਰ ਇਸ ਸਮੇਂ ਲਾਗੂ ਕਿਸੇ ਵੀ ਨਿਯਮ ਜਾਂ ਲਾਗੂ
                      ਕਾਨੂੰਨਾਂ ਦੀ ਉਲੰਘਣਾ ਕਰਨਾ; ਅਤੇ{" "}
                    </li>
                    <li>
                      ਇਹਨਾਂ ਨਿਯਮਾਂ ਅਤੇ ਸ਼ਰਤਾਂ ਨੂੰ ਅਨੈਤਿਕ ਢੰਗ ਨਾਲ ਹੇਰਾਫੇਰੀ ਕਰਨਾ
                      ਜਾਂ ਸ਼ੋਸ਼ਣ ਕਰਨਾ ਜਾਂ ਵੈੱਬਸਾਈਟ ਦੀ ਵਰਤੋਂ ਲਈ ਕੋਈ ਹੋਰ ਨਿਯਮ ਅਤੇ
                      ਸ਼ਰਤਾਂ ਜੋ ਕਿ ਨਹੀਂ ਹੈ{" "}
                      <a href="https://shramanhealthcare.com/">
                      ਸ਼ਰਮਨਹੈਲਥਕੇਅਰ
                      </a>{" "}
                    </li>
                  </ul>
                  <h2>ਯੂਜ਼ਰ ਵਾਰੰਟੀ ਅਤੇ ਪ੍ਰਤੀਨਿਧਤਾ:</h2>
                  <p>
                    ਉਪਭੋਗਤਾ ਗਾਰੰਟੀ ਦਿੰਦਾ ਹੈ, ਵਾਰੰਟੀ ਦਿੰਦਾ ਹੈ ਅਤੇ ਮਨਜ਼ੂਰੀ ਦਿੰਦਾ
                    ਹੈ ਕਿ ਤੁਸੀਂ ਉਸ ਸਮੱਗਰੀ ਦੇ ਮਾਲਕ ਹੋ ਜੋ ਤੁਸੀਂ ਜਮ੍ਹਾਂ ਕਰਦੇ ਹੋ ਜਾਂ
                    ਹੋਰ ਤਰੀਕੇ ਨਾਲ ਸਮੱਗਰੀ ਦੀ ਵਰਤੋਂ ਕਰਨ ਲਈ ਅਧਿਕਾਰਤ ਹੋ ਅਤੇ ਇਹ
                    ਸਮੱਗਰੀ ਜਾਇਦਾਦ ਜਾਂ ਬੌਧਿਕ ਸੰਪਤੀ ਅਧਿਕਾਰਾਂ ਜਾਂ ਦੂਜਿਆਂ ਦੇ ਕਿਸੇ
                    ਹੋਰ ਅਧਿਕਾਰਾਂ ਦੀ ਉਲੰਘਣਾ ਨਹੀਂ ਕਰਦੀ ਹੈ। ਤੁਸੀਂ ਇਹ ਵੀ ਮਾਨਤਾ ਲੈਂਦੇ
                    ਹੋ ਕਿ ਤੁਹਾਡੀ ਜਾਣਕਾਰੀ ਅਨੁਸਾਰ, ਕਿਸੇ ਵੀ ਸਮੱਗਰੀ ਨਾਲ ਸਬੰਧਤ ਕੋਈ
                    ਕਾਰਵਾਈ, ਮੁਕੱਦਮਾ, ਕਾਰਵਾਈ, ਜਾਂ ਜਾਂਚ ਦੀ ਧਮਕੀ ਨਹੀਂ ਦਿੱਤੀ ਗਈ ਹੈ,
                    ਜਿਸ ਵਿੱਚ ਟ੍ਰੇਡਮਾਰਕ, ਕਾਪੀਰਾਈਟ ਅਤੇ ਵਪਾਰਕ ਨਾਮ ਸੇਵਾ ਚਿੰਨ੍ਹ ਸ਼ਾਮਲ
                    ਹਨ ਜੋ ਤੁਹਾਡੇ ਦੁਆਰਾ ਪਹਿਲਾਂ ਜਾਂ ਵਰਤਮਾਨ ਵਿੱਚ ਦੇ ਸੰਬੰਧ ਵਿੱਚ ਵਰਤੀ
                    ਜਾਂਦੀ ਸੀ।{" "}
                    <a href="https://shramanhealthcare.com/">
                    ਸ਼ਰਮਨਹੈਲਥਕੇਅਰ
                    </a>{" "}
                  </p>
                  <h2>7. ਬੌਧਿਕ ਸੰਪਤੀ ਅਧਿਕਾਰ</h2>
                  <p>
                    <b>a)</b>ਜਦੋਂ ਤੱਕ ਹੋਰ ਸੰਕੇਤ ਨਹੀਂ ਦਿੱਤਾ ਗਿਆ ਜਾਂ ਕਿਸੇ ਵੀ ਚੀਜ਼
                    ਵਿੱਚ ਸ਼ਾਮਲ ਨਾ ਹੋਵੇ ਕੋਈ ਵੀ ਵਿਵਾਦਪੂਰਨ ਜਾਂ ਕੋਈ ਮਲਕੀਅਤ ਸਮੱਗਰੀ ਜੋ
                    ਕਿਸੇ ਤੀਜੀ ਧਿਰ ਦੁਆਰਾ ਨਿਯੰਤਰਿਤ ਜਾਂ ਮਲਕੀਅਤ ਹੋਵੇ ਅਤੇ ਇਸ ਤਰ੍ਹਾਂ
                    ਸਪੱਸ਼ਟ ਤੌਰ 'ਤੇ ਜ਼ਿਕਰ ਕੀਤਾ ਗਿਆ ਹੋਵੇ,{" "}
                    <a href="https://shramanhealthcare.com/">
                    ਸ਼ਰਮਨਹੈਲਥਕੇਅਰ
                    </a>{" "}
                    ਉਪਰੋਕਤ ਵੈੱਬਸਾਈਟ ਦੇ ਸਾਰੇ ਬੌਧਿਕ ਸੰਪੱਤੀ ਅਧਿਕਾਰਾਂ ਦਾ ਮਾਲਕ ਹੈ,
                    ਜਿਸ ਵਿੱਚ ਬਿਨਾਂ ਕਿਸੇ ਸੀਮਾ ਦੇ, ਕੋਈ ਵੀ ਅਤੇ ਸਾਰੇ ਅਧਿਕਾਰ, ਉਪਯੋਗਤਾ
                    ਮਾਡਲਾਂ ਵਿੱਚ ਅਤੇ ਉਹਨਾਂ ਦੇ ਸਿਰਲੇਖ ਅਤੇ ਹਿੱਤ, ਟ੍ਰੇਡਮਾਰਕ ਅਤੇ
                    ਵਪਾਰਕ ਨਾਮ, ਸੇਵਾ ਚਿੰਨ੍ਹ, ਕਾਪੀਰਾਈਟ, ਸੰਬੰਧਿਤ ਅਧਿਕਾਰ, ਪੇਟੈਂਟ,
                    ਡਿਜ਼ਾਈਨ, ਗਿਆਨ, ਵਪਾਰਕ ਰਾਜ਼ ਅਤੇ ਕਾਢਾਂ (ਭਾਵੇਂ ਪੇਟੈਂਟਯੋਗ ਹੋਣ ਜਾਂ
                    ਨਾ ਹੋਣ), ਸਦਭਾਵਨਾ, ਸਰੋਤ ਕੋਡ, ਮੈਟਾ ਟੈਗ, ਡੇਟਾਬੇਸ, ਟੈਕਸਟ,
                    ਸਮੱਗਰੀ, ਗ੍ਰਾਫਿਕਸ, ਆਈਕਨ, ਅਤੇ ਹਾਈਪਰਲਿੰਕ ਸ਼ਾਮਲ ਹਨ। ਤੁਸੀਂ ਸਹਿਮਤ
                    ਹੋ ਕਿ ਤੁਸੀਂ ਵੈੱਬਸਾਈਟ ਤੋਂ ਕਿਸੇ ਵੀ ਸਮੱਗਰੀ ਦੀ ਵਰਤੋਂ, ਡੁਪਲੀਕੇਟ
                    ਜਾਂ ਪ੍ਰਸਾਰਿਤ ਨਹੀਂ ਕਰੋਗੇ।{" "}
                    <a href="https://shramanhealthcare.com/">
                    ਸ਼ਰਮਨਹੈਲਥਕੇਅਰ
                    </a>{" "}
                    ਤੋਂ ਆਗਿਆ ਪ੍ਰਾਪਤ ਕੀਤੇ ਬਿਨਾਂ{" "}
                    <a href="https://shramanhealthcare.com/">
                    ਸ਼ਰਮਨਹੈਲਥਕੇਅਰ
                    </a>{" "}
                    .
                  </p>
                  <p>
                    <b>b)</b> ਉਪਰੋਕਤ ਦੇ ਬਾਵਜੂਦ, ਇਹ ਸਪੱਸ਼ਟ ਤੌਰ 'ਤੇ ਸਪੱਸ਼ਟ ਹੈ ਕਿ
                    ਤੁਸੀਂ ਮਾਲਕੀ ਨੂੰ ਪਛਾਣੋਗੇ ਅਤੇ ਕਿਸੇ ਵੀ ਸਮੱਗਰੀ ਲਈ ਪੂਰੀ ਤਰ੍ਹਾਂ
                    ਜ਼ਿੰਮੇਵਾਰ ਹੋਵੋਗੇ ਜੋ ਤੁਸੀਂ ਕਿਸੇ ਵੀ ਸੇਵਾ ਦੀ ਵਰਤੋਂ ਕਰਦੇ ਸਮੇਂ
                    ਪ੍ਰਬੰਧ ਕਰਦੇ ਹੋ ਜਾਂ ਅਪਲੋਡ ਕਰਦੇ ਹੋ, ਜਿਸ ਵਿੱਚ ਕੋਈ ਵੀ ਡੇਟਾ ਜਾਂ
                    ਤਸਵੀਰਾਂ, ਫੋਟੋਆਂ, ਸੰਗੀਤ, ਆਵਾਜ਼, ਜਾਂ ਵੀਡੀਓ ਰਾਹੀਂ ਕਿਸੇ ਵੀ ਕਿਸਮ
                    ਦੀ ਜਾਣਕਾਰੀ ਸ਼ਾਮਲ ਹੈ, ਅਤੇ ਇਹਨਾਂ ਤੱਕ ਸੀਮਿਤ ਨਹੀਂ ਹੈ, ਜਿਸਨੂੰ
                    ਤੁਸੀਂ ਸਾਡੀਆਂ ਵੱਖ-ਵੱਖ ਸਿਹਤ ਸੰਭਾਲ ਸੇਵਾਵਾਂ ਦੀ ਵਰਤੋਂ ਕਰਦੇ ਸਮੇਂ
                    ਅੱਪਲੋਡ, ਪ੍ਰਸਾਰਿਤ ਜਾਂ ਸਟੋਰ ਕਰ ਸਕਦੇ ਹੋ। ਹਾਲਾਂਕਿ, ਉਤਪਾਦ ਅਨੁਕੂਲਨ
                    ਸੇਵਾ ਦੇ ਸੰਬੰਧ ਵਿੱਚ, ਤੁਸੀਂ ਇਸ ਗੱਲ ਨਾਲ ਵੀ ਸਹਿਮਤ ਹੁੰਦੇ ਹੋ ਕਿ
                    ਸਮੱਗਰੀ ਨੂੰ ਅੱਪਲੋਡ ਅਤੇ ਪੋਸਟ ਕਰਕੇ{" "}
                    <a href="https://shramanhealthcare.com/">
                    ਸ਼ਰਮਨਹੈਲਥਕੇਅਰ
                    </a>{" "}
                    ਤੀਜੀ ਧਿਰ ਦੇ ਉਪਭੋਗਤਾਵਾਂ ਦੁਆਰਾ ਤੁਹਾਡੀ ਸਮੱਗਰੀ ਨੂੰ ਜਨਤਕ ਤੌਰ 'ਤੇ
                    ਦੇਖਣ ਅਤੇ ਡੁਪਲੀਕੇਸ਼ਨ ਜਾਂ ਵਰਤੋਂ ਲਈ, ਤੁਸੀਂ ਉਸ ਉਪਭੋਗਤਾ ਨੂੰ
                    ਸਵੀਕਾਰ ਕਰਦੇ ਹੋ ਜਿਸਦੇ ਦੁਆਰਾ ਤੁਸੀਂ ਇਸਦੀ ਵਰਤੋਂ ਲਈ ਇੱਕ
                    ਗੈਰ-ਨਿਵੇਕਲਾ ਲਾਇਸੈਂਸ ਨਿਰਧਾਰਤ ਕਰਦੇ ਹੋ।
                  </p>
                  <h2>8. ਵਾਰੰਟੀਆਂ/ਦੇਣਦਾਰੀ ਦੀ ਸੀਮਾ ਦਾ ਬੇਦਾਅਵਾ</h2>
                  <p>
                    {" "}
                    <a href="https://shramanhealthcare.com/">
                    ਸ਼ਰਮਨਹੈਲਥਕੇਅਰ
                    </a>{" "}
                    ਨੇ ਇਹ ਯਕੀਨੀ ਬਣਾਇਆ ਹੈ ਕਿ ਸਾਡੀ ਵੈੱਬਸਾਈਟ 'ਤੇ ਸਾਰੀ ਜਾਣਕਾਰੀ ਸਹੀ
                    ਹੈ,{" "}
                    <a href="https://shramanhealthcare.com/">
                    ਸ਼ਰਮਨਹੈਲਥਕੇਅਰ
                    </a>{" "}
                    ਕਿਸੇ ਵੀ ਡੇਟਾ, ਜਾਣਕਾਰੀ, ਉਤਪਾਦ ਜਾਂ ਸੇਵਾ ਦੀ ਗੁਣਵੱਤਾ, ਨਿਸ਼ਚਤਤਾ
                    ਜਾਂ ਸੰਪੂਰਨਤਾ ਬਾਰੇ ਨਾ ਤਾਂ ਲਾਇਸੈਂਸ ਦਿੰਦਾ ਹੈ ਅਤੇ ਨਾ ਹੀ ਕੋਈ
                    ਚਿੱਤਰਣ ਕਰਦਾ ਹੈ। ਕਿਸੇ ਵੀ ਸਥਿਤੀ ਵਿੱਚ{" "}
                    <a href="https://shramanhealthcare.com/">
                    ਸ਼ਰਮਨਹੈਲਥਕੇਅਰ
                    </a>{" "}
                    ਕਿਸੇ ਵੀ ਸਿੱਧੇ, ਅਸਿੱਧੇ, ਅਨੁਸ਼ਾਸਨੀ, ਦੁਰਘਟਨਾ, ਵਿਸ਼ੇਸ਼, ਪਰਿਣਾਮੀ
                    ਨੁਕਸਾਨ ਜਾਂ ਕਿਸੇ ਹੋਰ ਨੁਕਸਾਨ ਲਈ ਜ਼ਿੰਮੇਵਾਰ ਹੋਵੇਗਾ ਜਿਸਦੇ ਨਤੀਜੇ
                    ਵਜੋਂ:
                  </p>
                  <p>
                    <b>(a)</b> ਸੇਵਾਵਾਂ ਜਾਂ ਉਤਪਾਦਾਂ ਦੀ ਵਰਤੋਂ ਕਰਨ ਲਈ ਜਾਂ ਸੇਵਾਵਾਂ
                    ਜਾਂ ਉਤਪਾਦਾਂ ਦੀ ਵਰਤੋਂ ਕਰਨ ਵਿੱਚ ਅਸਮਰੱਥਾ ;
                  </p>
                  <p>
                    <b>(b)</b> ਉਪਭੋਗਤਾ ਦੇ ਪ੍ਰਸਾਰਣ ਜਾਂ ਉਪਭੋਗਤਾ ਦੇ ਡੇਟਾ ਤੱਕ
                    ਗੈਰ-ਕਾਨੂੰਨੀ ਪਹੁੰਚ ਜਾਂ ਸੋਧ;
                  </p>
                  <p>
                    <b>(c)</b> ਸੇਵਾਵਾਂ ਦਾ ਵਰਣਨ ਕਰਨ ਵਾਲਾ ਕੋਈ ਹੋਰ ਮਾਮਲਾ; ਜਿਸ ਵਿੱਚ
                    ਵਰਤੋਂ, ਡੇਟਾ ਜਾਂ ਮੁਨਾਫ਼ੇ ਦੇ ਨੁਕਸਾਨ ਲਈ ਹਰਜਾਨੇ ਸ਼ਾਮਲ ਹਨ, ਅਤੇ
                    ਇਹਨਾਂ ਤੱਕ ਸੀਮਿਤ ਨਹੀਂ ਹਨ, ਜੋ ਕਿ ਵਰਤੋਂ ਜਾਂ ਪ੍ਰਦਰਸ਼ਨ ਤੋਂ ਪੈਦਾ
                    ਹੁੰਦੇ ਹਨ ਜਾਂ ਕਿਸੇ ਵੀ ਤਰੀਕੇ ਨਾਲ ਜੁੜੇ ਹੁੰਦੇ ਹਨ{" "}
                    <a href="https://shramanhealthcare.com/">
                    ਸ਼ਰਮਨਹੈਲਥਕੇਅਰ
                    </a>{" "}
                    ਜਾਂ ਇਸਦੀਆਂ ਸੇਵਾਵਾਂ। ਨਾ ਹੀ{" "}
                    <a href="https://shramanhealthcare.com/">
                    ਸ਼ਰਮਨਹੈਲਥਕੇਅਰ
                    </a>{" "}
                    ਵੈੱਬਸਾਈਟ ਜਾਂ ਇਸਦੀਆਂ ਸੇਵਾਵਾਂ ਦੀ ਵਰਤੋਂ ਕਰਨ ਵਿੱਚ ਦੇਰੀ ਜਾਂ
                    ਅਸਮਰੱਥਾ, ਸੇਵਾਵਾਂ ਪ੍ਰਦਾਨ ਕਰਨ ਦੀ ਵਿਵਸਥਾ ਜਾਂ ਅਸਫਲਤਾ, ਜਾਂ
                    ਵੈੱਬਸਾਈਟ ਰਾਹੀਂ ਪ੍ਰਾਪਤ ਕੀਤੀ ਗਈ ਕਿਸੇ ਵੀ ਕਿਸਮ ਦੀ ਜਾਣਕਾਰੀ,
                    ਸਾਫਟਵੇਅਰ, ਉਤਪਾਦ, ਸੇਵਾਵਾਂ ਅਤੇ ਸੰਬੰਧਿਤ ਗ੍ਰਾਫਿਕਸ ਲਈ, ਜਾਂ
                    ਵੈੱਬਸਾਈਟ ਦੀ ਵਰਤੋਂ ਤੋਂ ਉਤਪੰਨ ਹੋਣ ਲਈ ਜ਼ਿੰਮੇਵਾਰ ਹੋਣਾ, ਭਾਵੇਂ
                    ਇਕਰਾਰਨਾਮੇ, ਟੋਰਟ, ਲਾਪਰਵਾਹੀ, ਸਖ਼ਤ ਦੇਣਦਾਰੀ ਜਾਂ ਕਿਸੇ ਹੋਰ ਤਰ੍ਹਾਂ
                    ਦੇ ਆਧਾਰ 'ਤੇ ਹੋਵੇ। ਇਸ ਤੋਂ ਇਲਾਵਾ,{" "}
                    <a href="https://shramanhealthcare.com/">
                    ਸ਼ਰਮਨਹੈਲਥਕੇਅਰ
                    </a>{" "}
                    ਜੇਕਰ ਵੈੱਬਸਾਈਟ ਸਮੇਂ-ਸਮੇਂ 'ਤੇ ਰੱਖ-ਰਖਾਅ ਦੇ ਕਾਰਜਾਂ ਦੌਰਾਨ ਉਪਲਬਧ
                    ਨਹੀਂ ਹੁੰਦੀ ਹੈ ਜਾਂ ਤਕਨੀਕੀ ਕਾਰਨਾਂ ਕਰਕੇ ਜਾਂ ਇਸ ਤੋਂ ਇਲਾਵਾ ਕਿਸੇ
                    ਹੋਰ ਕਾਰਨ ਕਰਕੇ ਸਾਡੀ ਵੈੱਬਸਾਈਟ ਨਾਲ ਕੁਨੈਕਸ਼ਨ ਦੀ ਅਣਯੋਜਨਾਬੱਧ
                    ਮੁਅੱਤਲੀ ਹੁੰਦੀ ਹੈ ਤਾਂ ਜ਼ਿੰਮੇਵਾਰ ਨਹੀਂ ਹੋਵੇਗਾ।{" "}
                    <a href="https://shramanhealthcare.com/">
                    ਸ਼ਰਮਨਹੈਲਥਕੇਅਰ
                    </a>{" "}
                    ਦਾ ਕੰਟਰੋਲ। ਉਪਭੋਗਤਾ ਸਵੀਕਾਰ ਕਰਦਾ ਹੈ ਕਿ ਵੈੱਬਸਾਈਟ ਰਾਹੀਂ ਪ੍ਰਾਪਤ
                    ਕੀਤੀ ਗਈ ਕੋਈ ਵੀ ਜਾਣਕਾਰੀ ਜਾਂ ਡੇਟਾ ਪੂਰੀ ਤਰ੍ਹਾਂ ਉਨ੍ਹਾਂ ਦੀ ਆਪਣੀ
                    ਦੂਰਅੰਦੇਸ਼ੀ ਅਤੇ ਜੋਖਮ 'ਤੇ ਕੀਤਾ ਗਿਆ ਹੈ ਅਤੇ ਉਹ ਅਜਿਹੀ ਸਮੱਗਰੀ
                    ਅਤੇ/ਜਾਂ ਡੇਟਾ ਦੇ ਡਾਊਨਲੋਡ ਦੇ ਨਤੀਜੇ ਵਜੋਂ ਆਪਣੇ ਕੰਪਿਊਟਰ ਸਿਸਟਮ ਨੂੰ
                    ਹੋਣ ਵਾਲੇ ਕਿਸੇ ਵੀ ਨੁਕਸਾਨ ਜਾਂ ਡੇਟਾ ਦੇ ਕਿਸੇ ਵੀ ਨੁਕਸਾਨ ਲਈ ਪੂਰੀ
                    ਤਰ੍ਹਾਂ ਜ਼ਿੰਮੇਵਾਰ ਹੋਣਗੇ।
                  </p>
                  <h2>9. ਮੁਆਵਜ਼ਾ</h2>
                  <p>
                    ਉਪਭੋਗਤਾ ਬਚਾਅ ਕਰਨ ਲਈ ਸਹਿਮਤ ਹੈ ਤੁਸੀਂ ਮੁਆਵਜ਼ਾ ਦੇਣ, ਬਚਾਅ ਕਰਨ ਅਤੇ
                    ਨੁਕਸਾਨ ਰਹਿਤ ਰੱਖਣ ਲਈ ਸਹਿਮਤ ਹੋ।{" "}
                    <a href="https://shramanhealthcare.com/">
                    ਸ਼ਰਮਨਹੈਲਥਕੇਅਰ
                    </a>{" "}
                    ਕਿਸੇ ਵੀ ਅਤੇ ਸਾਰੇ ਨੁਕਸਾਨ, ਕਰਜ਼ੇ, ਦੋਸ਼ਾਂ, ਨੁਕਸਾਨ, ਲਾਗਤਾਂ ਅਤੇ
                    ਖਰਚਿਆਂ (ਕਾਨੂੰਨੀ ਫੀਸਾਂ ਅਤੇ ਇਸ ਨਾਲ ਸਬੰਧਤ ਖਰਚੇ ਅਤੇ ਇਸ 'ਤੇ ਵਸੂਲੇ
                    ਜਾਣ ਵਾਲੇ ਵਿਆਜ ਸਮੇਤ) ਤੋਂ ਅਤੇ ਉਨ੍ਹਾਂ ਦੇ ਵਿਰੁੱਧ ਬਹਿਸ ਕਰੋ ਜਾਂ
                    ਕੀਤੇ ਗਏ ਦੁਆਰਾ ਕੀਤੇ ਗਏ{" "}
                    <a href="https://shramanhealthcare.com/">
                    ਸ਼ਰਮਨਹੈਲਥਕੇਅਰ
                    </a>{" "}
                    ਜੋ ਕਿ ਇਹਨਾਂ ਨਿਯਮਾਂ ਅਤੇ ਸ਼ਰਤਾਂ ਨਾਲ ਸਹਿਮਤ ਹੋਣ ਵਾਲੇ ਉਪਭੋਗਤਾ
                    ਦੁਆਰਾ ਕਿਸੇ ਵੀ ਪ੍ਰਤੀਨਿਧਤਾ, ਵਾਰੰਟੀ ਜਾਂ ਕੀਤੇ ਗਏ ਸਮਝੌਤੇ ਜਾਂ ਕੀਤੇ
                    ਜਾਣ ਵਾਲੇ ਫਰਜ਼ ਦੀ ਉਲੰਘਣਾ ਜਾਂ ਗੈਰ-ਪ੍ਰਦਰਸ਼ਨ ਤੋਂ ਪੈਦਾ ਹੁੰਦੇ ਹਨ,
                    ਨਤੀਜੇ ਵਜੋਂ ਹੁੰਦੇ ਹਨ ਜਾਂ ਭੁਗਤਾਨਯੋਗ ਹੋ ਸਕਦੇ ਹਨ।
                  </p>
                  <h2>10. ਕੀਮਤ</h2>
                  <p>
                    ਸਾਡੇ ਸਿਹਤ ਸੰਭਾਲ ਉਤਪਾਦਾਂ ਦੀਆਂ ਕੀਮਤਾਂ ਸਾਡੀ ਵੈੱਬਸਾਈਟ 'ਤੇ ਦੱਸੀਆਂ
                    ਗਈਆਂ ਹਨ ਅਤੇ ਹਵਾਲੇ ਦੁਆਰਾ ਇਹਨਾਂ ਸ਼ਰਤਾਂ ਵਿੱਚ ਸ਼ਾਮਲ ਕੀਤੀਆਂ ਗਈਆਂ
                    ਹਨ। ਹਰੇਕ ਕੀਮਤ ਭਾਰਤੀ ਰੁਪਏ ਵਿੱਚ ਦੱਸੀ ਜਾਵੇਗੀ। ਕੀਮਤਾਂ, ਉਤਪਾਦ ਅਤੇ
                    ਸੇਵਾਵਾਂ ਇੱਥੇ ਬਦਲ ਸਕਦੀਆਂ ਹਨ{" "}
                    <a href="https://shramanhealthcare.com/">
                    ਸ਼ਰਮਨਹੈਲਥਕੇਅਰ
                    </a>{" "}
                    ਵਿਵੇਕ.
                  </p>
                  <h2>11. ਸ਼ਿਪਿੰਗ</h2>
                  <p>
                    ਸ਼ਰਮਨ ਹੈਲਥ ਕੇਅਰ ਉਤਪਾਦ ਦੀ ਮਿਆਦ ਪੁੱਗਣ ਤੋਂ ਘੱਟੋ-ਘੱਟ ਇੱਕ ਮਹੀਨਾ
                    ਪਹਿਲਾਂ ਚੀਜ਼ਾਂ ਭੇਜਣਾ ਯਕੀਨੀ ਬਣਾਉਂਦਾ ਹੈ।
                  </p>
                  <h2>12. ਸਮਾਪਤੀ</h2>
                  <p>
                    ਸ਼ਰਮਨ ਹੈਲਥ ਕੇਅਰ ਉਤਪਾਦ ਦੀ ਮਿਆਦ ਪੁੱਗਣ ਤੋਂ ਘੱਟੋ-ਘੱਟ ਇੱਕ ਮਹੀਨਾ
                    ਪਹਿਲਾਂ ਚੀਜ਼ਾਂ ਭੇਜਣਾ ਯਕੀਨੀ ਬਣਾਉਂਦਾ ਹੈ
                  </p>
                  <p>
                    <b>(a)</b>{" "}
                    <a href="https://shramanhealthcare.com/">
                    ਸ਼ਰਮਨਹੈਲਥਕੇਅਰ
                    </a>{" "}
                    ਜੇਕਰ ਇਹ ਮੰਨਦਾ ਹੈ ਕਿ ਤੁਸੀਂ ਆਪਣੀ ਇਕੱਲੀ ਅਤੇ ਪੂਰੀ ਦੂਰਅੰਦੇਸ਼ੀ
                    ਵਿੱਚ ਇਨ੍ਹਾਂ ਵਿੱਚੋਂ ਕਿਸੇ ਵੀ ਨਿਯਮ ਦੀ ਉਲੰਘਣਾ ਕੀਤੀ ਹੈ, ਜਾਂ
                    ਅਨੈਤਿਕ ਤੌਰ 'ਤੇ ਹੇਰਾਫੇਰੀ ਕੀਤੀ ਹੈ ਜਾਂ ਸ਼ੋਸ਼ਣ ਕੀਤਾ ਹੈ, ਤਾਂ ਇਹ
                    ਵੈੱਬਸਾਈਟ ਜਾਂ ਇਸ ਦੀਆਂ ਕਿਸੇ ਵੀ ਸੇਵਾਵਾਂ ਦੀ ਪੂਰੀ ਜਾਂ ਛੋਟੀ ਮਿਆਦ
                    ਲਈ ਵਰਤੋਂ ਨੂੰ ਖਤਮ ਕਰ ਸਕਦਾ ਹੈ।
                  </p>
                  <p>
                    <b>(b)</b> ਉਪਰੋਕਤ ਧਾਰਾ 14.a ਦੇ ਬਾਵਜੂਦ, ਇਹ ਸ਼ਰਤਾਂ ਅਣਮਿੱਥੇ
                    ਸਮੇਂ ਲਈ ਲਾਗੂ ਰਹਿਣਗੀਆਂ ਜਦੋਂ ਤੱਕ ਕਿ{" "}
                    <a href="https://shramanhealthcare.com/">
                    ਸ਼ਰਮਨਹੈਲਥਕੇਅਰ
                    </a>{" "}
                    ਉਹਨਾਂ ਨੂੰ ਖਤਮ ਕਰਨ ਦੀ ਚੋਣ ਕਰਦਾ ਹੈ।
                  </p>
                  <p>
                    <b>(c)</b> ਜੇਕਰ ਤੁਸੀਂ ਜਾਂ{" "}
                    <a href="https://shramanhealthcare.com/">
                    ਸ਼ਰਮਨਹੈਲਥਕੇਅਰ
                    </a>{" "}
                    ਵੈੱਬਸਾਈਟ ਜਾਂ ਇਸਦੀਆਂ ਕਿਸੇ ਵੀ ਸਿਹਤ ਸੰਭਾਲ ਸੇਵਾਵਾਂ ਦੀ ਤੁਹਾਡੀ
                    ਵਰਤੋਂ ਨੂੰ ਖਤਮ ਕਰਦਾ ਹੈ,{" "}
                    <a href="https://shramanhealthcare.com/">
                    ਸ਼ਰਮਨਹੈਲਥਕੇਅਰ
                    </a>{" "}
                    ਤੁਹਾਡੀ ਸੇਵਾ ਦੀ ਵਰਤੋਂ ਨਾਲ ਸਬੰਧਤ ਕਿਸੇ ਵੀ ਸਮੱਗਰੀ ਜਾਂ ਹੋਰ ਸਮੱਗਰੀ
                    ਨੂੰ ਮਿਟਾ ਸਕਦਾ ਹੈ ਅਤੇ{" "}
                    <a href="https://shramanhealthcare.com/">
                    ਸ਼ਰਮਨਹੈਲਥਕੇਅਰ
                    </a>{" "}
                    ਅਜਿਹਾ ਕਰਨ ਲਈ ਤੁਹਾਡੀ ਜਾਂ ਕਿਸੇ ਤੀਜੀ ਧਿਰ ਪ੍ਰਤੀ ਕੋਈ ਜ਼ਿੰਮੇਵਾਰੀ
                    ਨਹੀਂ ਹੋਵੇਗੀ।
                  </p>
                  <p>
                    <b>(d)</b> ਤੁਹਾਨੂੰ ਕਿਸੇ ਵੀ ਸੇਵਾ ਜਾਂ ਉਤਪਾਦ ਲਈ ਭੁਗਤਾਨ ਕਰਨ ਲਈ
                    ਜ਼ਿੰਮੇਵਾਰ ਠਹਿਰਾਇਆ ਜਾਵੇਗਾ ਜੋ ਤੁਸੀਂ ਕਿਸੇ ਵੀ ਧਿਰ ਦੁਆਰਾ ਆਪਣੀ
                    ਸਮਾਪਤੀ ਦੇ ਸਮੇਂ ਤੋਂ ਪਹਿਲਾਂ ਹੀ ਆਰਡਰ ਕੀਤਾ ਹੈ। ਇਸ ਤੋਂ ਇਲਾਵਾ,
                    ਤੁਹਾਨੂੰ ਉਪਭੋਗਤਾ ਲਾਇਸੈਂਸ ਸਮਝੌਤੇ ਦੇ ਅਨੁਸਾਰ ਤੁਹਾਡੇ ਰਾਇਲਟੀ
                    ਭੁਗਤਾਨਾਂ ਲਈ ਨਾਮਜ਼ਦ ਕੀਤਾ ਜਾਵੇਗਾ ਜੋ ਤੁਹਾਨੂੰ ਕਾਨੂੰਨੀ ਤੌਰ 'ਤੇ
                    ਪ੍ਰਾਪਤ ਹੋਇਆ ਹੈ ਜਾਂ ਮੰਨਿਆ ਜਾਂਦਾ ਹੈ।
                  </p>
                  <h2>13. ਪ੍ਰਬੰਧਕੀ ਕਾਨੂੰਨ</h2>
                  <p>
                    ਇਹ ਨਿਯਮ ਅਤੇ ਸ਼ਰਤਾਂ ਭਾਰਤ ਦੇ ਕਾਨੂੰਨਾਂ ਅਨੁਸਾਰ ਕਾਨੂੰਨਾਂ ਦੇ
                    ਸਿਧਾਂਤਾਂ ਦੀ ਉਲੰਘਣਾ ਅਤੇ ਸਬੰਧ ਵਿੱਚ ਪੈਦਾ ਹੋਣ ਵਾਲੇ ਵਿਵਾਦਾਂ ਦੇ
                    ਹਵਾਲੇ ਤੋਂ ਬਿਨਾਂ ਦੁਆਰਾ ਨਿਯੰਤਰਿਤ ਅਤੇ ਰਚੀਆਂ ਜਾਂਦੀਆਂ ਹਨ
                  </p>

                  <h2>14. ਗੰਭੀਰਤਾ</h2>
                  <p>
                    ਜੇਕਰ ਨਿਯਮਾਂ ਦਾ ਕੋਈ ਵੀ ਸਮਝੌਤਾ ਅਵੈਧ ਜਾਂ ਅੰਸ਼ਕ ਜਾਂ ਸੰਪੂਰਨ ਤੌਰ
                    'ਤੇ ਲਾਗੂ ਨਾ ਹੋਣ ਯੋਗ ਸਾਬਤ ਹੁੰਦਾ ਹੈ, ਤਾਂ ਅਜਿਹੀ ਅਵੈਧਤਾ ਜਾਂ ਲਾਗੂ
                    ਨਾ ਹੋਣਯੋਗਤਾ ਸਿਰਫ਼ ਅਜਿਹੇ ਸਮਝੌਤਿਆਂ ਜਾਂ ਅਜਿਹੇ ਸਮਝੌਤਿਆਂ ਦੇ ਹਿੱਸੇ
                    ਨਾਲ ਜੁੜੀ ਹੋਵੇਗੀ ਅਤੇ ਅਜਿਹੇ ਸਮਝੌਤੇ ਦਾ ਬਾਕੀ ਹਿੱਸਾ ਅਤੇ ਇਨ੍ਹਾਂ
                    ਨਿਯਮਾਂ ਦੇ ਹੋਰ ਸਾਰੇ ਸਮਝੌਤੇ ਪੂਰੀ ਤਰ੍ਹਾਂ ਲਾਗੂ ਅਤੇ ਪ੍ਰਭਾਵੀ
                    ਰਹਿਣਗੇ।
                  </p>
                  <h2>15. ਦੁਰਵਿਵਹਾਰ ਦੀ ਰਿਪੋਰਟ ਕਰੋ</h2>
                  <p>
                    ਵੈੱਬਸਾਈਟ 'ਤੇ ਅਪਲੋਡ ਕੀਤੇ ਗਏ ਹਰੇਕ ਡੇਟਾ ਲਈ ਉਪਭੋਗਤਾਵਾਂ ਨੂੰ ਪੂਰੀ
                    ਤਰ੍ਹਾਂ ਜਵਾਬਦੇਹ ਠਹਿਰਾਇਆ ਜਾ ਸਕਦਾ ਹੈ। ਉਪਭੋਗਤਾਵਾਂ ਨੂੰ ਆਪਣੇ ਅਪਲੋਡ
                    ਕੀਤੇ ਡੇਟਾ ਲਈ ਕਾਨੂੰਨੀ ਤੌਰ 'ਤੇ ਜ਼ਿੰਮੇਵਾਰ ਠਹਿਰਾਇਆ ਜਾ ਸਕਦਾ ਹੈ
                    ਅਤੇ ਜੇਕਰ ਉਸ ਜਾਣਕਾਰੀ ਵਿੱਚ, ਉਦਾਹਰਨ ਲਈ, ਟਿੱਪਣੀਆਂ ਸ਼ਾਮਲ ਹਨ ਜੋ
                    ਅਪਮਾਨਜਨਕ ਹਨ ਜਾਂ ਸਮੱਗਰੀ ਜੋ ਕਾਪੀਰਾਈਟ ਦੁਆਰਾ ਸੁਰੱਖਿਅਤ ਹਨ,
                    ਟ੍ਰੇਡਮਾਰਕ, ਆਦਿ। ਜੇਕਰ ਤੁਸੀਂ ਇਨ੍ਹਾਂ ਨਿਯਮਾਂ ਦੀ ਕਿਸੇ ਵੀ
                    ਦੁਰਵਿਵਹਾਰ ਜਾਂ ਉਲੰਘਣਾ ਦਾ ਸਾਹਮਣਾ ਕਰਦੇ ਹੋ, ਤਾਂ ਕਿਰਪਾ ਕਰਕੇ 'ਤੇ
                    ਰਿਪੋਰਟ ਕਰੋ
                    <a href="https://shramanhealthcare.com/">ਸਾਡੀ ਵੈੱਬਸਾਈਟ</a>।
                    , ਵਿਸ਼ਾ ਸਿਰਲੇਖ "ਦੁਰਵਿਵਹਾਰ ਦੀ ਰਿਪੋਰਟ ਕਰੋ" ਦੇ ਨਾਲ।
                  </p>
                  <h2>16. ਵਾਪਸੀ ਅਤੇ ਰਿਫੰਡ</h2>
                  <p>
                    <strong>I. ਮੂਲ ਵੱਲ ਵਾਪਸੀ-</strong>
                    <ul>
                      <li>
                        a). ਜਦੋਂ ਕੋਈ ਉਤਪਾਦ ਕੋਰੀਅਰ ਦੁਆਰਾ ਕਿਸੇ ਕਾਰਨ ਕਰਕੇ ਡਿਲੀਵਰ
                        ਨਹੀਂ ਕੀਤਾ ਜਾਂਦਾ ਹੈ ਜੋ ਕਿ ਨਿਸ਼ਚਿਤ ਨਹੀਂ ਹੈ, ਤਾਂ ਸ਼ਰਮਨ ਹੈਲਥ
                        ਕੇਅਰ ਸਾਡੀ ਸਹੂਲਤ ਤੇ ਉਤਪਾਦ ਪ੍ਰਾਪਤ ਕਰਨ ਤੋਂ ਬਾਅਦ ਪੂਰੀ ਰਕਮ
                        ਵਾਪਸ ਕਰ ਦੇਵੇਗਾ।
                      </li>
                      <li>
                        b). ਜਦੋਂ ਕੋਈ ਉਤਪਾਦ ਪ੍ਰਕਿਰਿਆ ਦੌਰਾਨ ਕੋਰੀਅਰ ਦੁਆਰਾ ਗੁਆਚ
                        ਜਾਂਦਾ ਹੈ। ਅਸੀਂ ਉਸੇ ਸਮੇਂ ਪੂਰੀ ਰਕਮ ਵਾਪਸ ਕਰ ਦੇਵਾਂਗੇ।
                      </li>
                    </ul>
                    <strong>II. ਗਾਹਕ ਵਾਪਸ ਕਰਦਾ ਹੈ</strong>
                    <ul>
                      <li>
                        a). ਗਾਹਕ ਵਾਪਸੀ ਦੀ ਬੇਨਤੀ ਸਿਰਫ਼ ਉਦੋਂ ਹੀ ਕਰਨ ਲਈ ਜ਼ਿੰਮੇਵਾਰ
                        ਹਨ ਜਦੋਂ ਡਿਲੀਵਰ ਕੀਤਾ ਗਿਆ ਉਤਪਾਦ ਗਲਤ ਜਾਂ ਖਰਾਬ ਹੋਵੇ।
                      </li>
                      <li>
                        b). ਗਾਹਕ ਈਮੇਲ ਰਾਹੀਂ ਅਰੋਗਯਮ ਦੀ ਸਹਾਇਤਾ ਟੀਮ ਨਾਲ ਸੰਪਰਕ ਕਰ
                        ਸਕਦੇ ਹਨ। ਸਾਡੀ ਟੀਮ ‘ਨੁਕਸਾਨ’ ਜਾਂ ‘ਗਲਤ ਵਸਤੂ’ ਦੇ ਕੁਝ ਸਬੂਤਾਂ
                        ਦੀ ਬੇਨਤੀ ਕਰੇਗੀ।
                      </li>
                    </ul>
                  </p>
                </div>
              </div>
              <div className="col-sm-4 col-12">
                <div className="refundimg  animate__animated animate__fadeInDown">
                  <img
                    src="refundpolicy.jpg"
                    className="img-fluid"
                    alt="privacy-policy"
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <Footer lang={lang} />
    </div>
  );
}
