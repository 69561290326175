import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Homepage(props) {
  const [menus, updateMenu] = useState([
    {
      menu: "Home",
      link: "/",
      active: true,
    },
    {
      menu: "Treatment",
      link: "/treatment",
      active: false,
    },
    {
      menu: "About Us",
      link: "/about-us",
      active: false,
    },
    {
      menu: "Contact",
      link: "/contact",
      active: false,
    },
    {
      menu: "Refund Policy",
      link: "/refund-policy",
      active: false,
    },
    {
      menu: "Blog",
      link: "/blog",
      active: false,
    },
    {
      menu: "Privacy Policy",
      link: "/privacypolicy",
      active: false,
    },
    {
      menu: "Disclaimer",
      link: "/disclaimer",
      active: false,
    },
    {
      menu: "होम",
      link: "/",
      active: true,
    },
    {
      menu: "ईलाज",
      link: "/treatment",
      active: false,
    },
    {
      menu: "हमारे बारे",
      link: "/about-us",
      active: false,
    },
    {
      menu: "संपर्क करें",
      link: "/contact",
      active: false,
    },
    {
      menu: "मनी बैक गरनटी",
      link: "/refund-policy",
      active: false,
    },
    {
      menu: "बलोग",
      link: "/blog",
      active: false,
    },
    {
      menu: "गोपनीयता नीति",
      link: "/privacypolicy",
      active: false,
    },
    {
      menu: "अस्वीकरण",
      link: "/disclaimer",
      active: false,
    },

    {
      menu: "ਹੋਮ",
      link: "/",
      active: true,
    },
    {
      menu: "ਇਲਾਜ",
      link: "/treatment",
      active: false,
    },
    {
      menu: "ਸਾਡੇ ਬਾਰੇ",
      link: "/about-us",
      active: false,
    },
    {
      menu: "ਸੰਪਰਕ ਕਰੋ",
      link: "/contact",
      active: false,
    },
    {
      menu: "ਮਨੀ ਬੈਕ ਗਰੰਟੀ",
      link: "/refund-policy",
      active: false,
    },
    {
      menu: "ਬਲਾਗ",
      link: "/blog",
      active: false,
    },
    {
      menu: "ਪਰਾਈਵੇਟ ਨੀਤੀ",
      link: "/privacypolicy",
      active: false,
    },
    {
      menu: "ਬੇਦਾਅਵਾ",
      link: "/disclaimer",
      active: false,
    },
  ]);

  const [vis, makeVis] = useState(false);

  useEffect(() => {
    getNavLink();
  }, []);

  function getNavLink() {
    // console.log(props.match.url);
    const { url } = props.match;
    let menus_copy = menus.slice();
    for (const menu of menus_copy) {
      if (menu.link === url) menu.active = true;
      else menu.active = false;
    }
    // console.log(menus_copy);
    updateMenu(menus_copy);
  }

  function changeLangFunc(e) {
    props.changeLang(e);
    window.scrollTo(0, 0);
  }

  function showDrawer() {
    let btn = document.getElementById("collapse-btn");
    btn.click();
  }

  return (
    <div className="site-header animate__animated animate__fadeInDown">
      <nav
        className="navbar navbar-expand-md navbar-dark navbardark"
        id="navbar"
      >
        <Link className="navbar-brand sitebrand" to="/">
          <img
            src="/logo.png"
            width="175"
            alt="shraman healthcare"
            className="img-fluid"
          />
        </Link>
        <div className="socialicon d-block d-sm-none">
          <ul>
            <li>
              <a
                className="fb"
                href="https://www.facebook.com/shramancare/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-facebook-official"></i>
              </a>
            </li>
            <li>
              <a
                className="yt"
                href="https://www.youtube.com/channel/UCYnwdA8P3npDvVrvSC5D7xQ?view_as=subscriber"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-youtube"></i>
              </a>
            </li>
          </ul>
        </div>
        <button
          className="navbar-toggler"
          id="collapse-btn"
          type="button"
          data-toggle={"collapse"}
          data-target="#navbarcollapse"
          aria-controls="navbarcollapse"
          aria-expanded="true"
          aria-label="Toggle navigation"
        >
          <svg
            className="bi bi-justify mt-1"
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke="#000"
              fillRule="evenodd"
              d="M2 12.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"
            />
          </svg>
        </button>

        <div className="col-12 d-block d-sm-none mb-2 text-center px-0 m-view">
          <div className="form-row">
            <div className="col-4 pl-0">
              <button
                className={
                  props.lang === "en" ? "btn btnlang" : "btn btn-primary"
                }
                value="en"
                onClick={(e) => {
                  // if (props.match.url.slice(0, 10) === '/treatment')
                  changeLangFunc(e);
                }}
              >
                English
              </button>
            </div>
            <div className="col-4">
              <button
                className={
                  props.lang === "hi" ? "btn btnlang" : "btn btn-primary"
                }
                value="hi"
                onClick={(e) => {
                  // if (props.match.url.slice(0, 10) === '/treatment')
                  changeLangFunc(e);
                }}
              >
                Hindi
              </button>
            </div>
            <div className="col-4 pr-0">
              <button
                className={
                  props.lang === "pb" ? "btn btnlang" : "btn btn-primary"
                }
                value="pb"
                onClick={(e) => {
                  // if (props.match.url.slice(0, 10) === '/treatment')
                  changeLangFunc(e);
                }}
              >
                Punjabi
              </button>
            </div>
          </div>
        </div>

        <div
          className="collapse navbar-collapse navbarmenu "
          id="navbarcollapse"
        >
          <ul className="navbar-nav mx-auto">
            {props.lang === "en"
              ? menus.map((menu, i) => {
                  if (i >= 0 && i <= 7) {
                    return (
                      <li key={i} className="nav-item" onClick={showDrawer}>
                        <Link
                          className={
                            menu.active === true
                              ? "nav-link active"
                              : "nav-link "
                          }
                          to={menu.link}
                        >
                          <span>{menu.menu}</span>
                        </Link>
                      </li>
                    );
                  } else {
                    return null;
                  }
                })
              : null}
            {props.lang === "hi"
              ? menus.map((menu, i) => {
                  if (i >= 8 && i <= 15) {
                    return (
                      <li key={i} className="nav-item" onClick={showDrawer}>
                        <Link
                          className={
                            menu.active === true
                              ? "nav-link active"
                              : "nav-link "
                          }
                          to={menu.link}
                        >
                          <span>{menu.menu}</span>
                        </Link>
                      </li>
                    );
                  } else {
                    return null;
                  }
                })
              : null}
            {props.lang === "pb"
              ? menus.map((menu, i) => {
                  if (i >= 16 && i <= 23) {
                    return (
                      <li key={i} className="nav-item" onClick={showDrawer}>
                        <Link
                          className={
                            menu.active === true
                              ? "nav-link active"
                              : "nav-link "
                          }
                          to={menu.link}
                        >
                          <span>{menu.menu}</span>
                        </Link>
                      </li>
                    );
                  } else {
                    return null;
                  }
                })
              : null}
            {/* <li className="nav-item">
                            <Link className="nav-link" to="/"><span>Home</span></Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/about-us"><span>About Us</span></Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/refund-policy"><span>Refund Policy</span></Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/treatment"><span>Treatment</span></Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/blog"><span>Blog</span></Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/contact-us"><span>Contact Us</span></Link>
                        </li> */}
          </ul>
          <div className="languagebtn d-none d-sm-block d-md-block d-lg-block">
            <ul>
              <li>
                <button
                  className={
                    props.lang === "en" ? "btn btnlang" : "btn btn-primary"
                  }
                  value="en"
                  onClick={(e) => {
                    // if (props.match.url.slice(0, 10) === "/treatment")
                    changeLangFunc(e);
                  }}
                >
                  English
                </button>
              </li>
              <li>
                <button
                  className={
                    props.lang === "hi" ? "btn btnlang" : "btn btn-primary"
                  }
                  value="hi"
                  onClick={(e) => {
                    // if (props.match.url.slice(0, 10) === "/treatment")
                    changeLangFunc(e);
                  }}
                >
                  Hindi
                </button>
              </li>
              <li>
                <button
                  className={
                    props.lang === "pb" ? "btn btnlang" : "btn btn-primary"
                  }
                  value="pb"
                  onClick={(e) => {
                    // if (props.match.url.slice(0, 10) === "/treatment")
                    changeLangFunc(e);
                  }}
                >
                  Punjabi
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
