import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Homepage from "./Homepage";
import AboutUs from "./AboutUs";
import RefundPolicy from "./RefundPolicy";
import Blog from "./Blog";
import ContactUs from "./ContactUs";
import Treatment from "./Treatment";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import NoRouteFound from "./NoRouteFound";
import PrivacyPolicy from "./Privacy";
import Disclaimer from "./Disclaimer";

window.localStorage.setItem("lang", "pb");
ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route exact path="/" component={Homepage} />
        <Route path="/about-us" component={AboutUs} />
        <Route path="/refund-policy" component={RefundPolicy} />
        <Route path="/blog" component={Blog} />
        <Route path="/treatment" component={Treatment} />
        <Route path="/contact" component={ContactUs} />
        <Route path="/privacypolicy" component={PrivacyPolicy} />
        <Route path="/disclaimer" component={Disclaimer} />
        <Route component={NoRouteFound} />
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
